/* eslint-disable */
// Libs
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Images
import closeIcon from '../assets/images/close.svg';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// style properties
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #0c153480;
  animation: ${OpacityAnimation} 0.2s;
  z-index: 2;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30.625rem;
  border-radius: 0.625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 .875rem #00000014;
  overflow: hidden;

  @media (max-width: 480px) {
    margin: 0;
    width: 95%;
    justify-content: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled.img`
  width: 1rem;
`;

const Container = styled.div`
  padding: 2.8125rem;
  width: 100%;

  @media (max-width: 480px) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  margin: 0 0 2.625rem;
  font: 600 2.1875rem 'Paralucent', sans-serif;
  color: #22254B;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
  max-height: 30.5rem;
  border-radius: .3125rem;
  border: .0625rem solid #22254B0D;
  background: #CAAAE50D;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: .375rem;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0.2rem;
    border-radius: .9375rem;
    background: #4A180526;
  }

  @media (max-width: 480px) {
    max-height: 26.5rem;
  }
`;

const Text = styled.p`
  margin: ${(props) => props.bold && '0'};
  width: 95%;
  font: ${(props) => (props.bold ? '600' : '200')} ${(props) => (props.bold ? '1rem' : '.85rem')} 'Soleto', sans-serif;
  color: ${(props) => (props.purple ? '#5321C4' : '#22254B')};
  font-style: ${(props) => props.italic && 'italic'};
  user-select: none;
  line-height: 1.375rem;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding-left: 6%;
`;

const ListItem = styled.li`
  margin: 0.25rem 0;
  width: 100%;
  font: 300 .625rem 'Soleto', sans-serif;
  color: #22254B;
  line-height: 1.375rem;
  user-select: none;
`;

const TextFooter = styled(Text)`
  font-size: 0.85rem;
  margin: 0;
`;

const TermsOfServicePT = ({ closeFunction }) => (
  <Overlay>
    <Wrapper>
      <CloseButton onClick={closeFunction}>
        <CloseIcon src={closeIcon} />
      </CloseButton>
      <Container>
        <Title bold>Termos de Uso</Title>
        <Content>
          <Text bold>Strytegy</Text>
          <Text purple>ÚLTIMA ATUALIZAÇÃO: 06 de julho de 2021</Text>
          <Text bold>1. Concordância com os Termos de Uso e informações iniciais</Text>
          <Text>
            <strong>1.1</strong> Estes Termos de Uso constituem um acordo legalmente vinculante feito entre você, usuário pessoa física ou pessoa jurídica, por meio de seu representante legal (<strong>“Você”</strong>), e a <strong>ISTI Soluções em Tecnologia Ltda</strong>, inscrita no CNPJ sob o n. 13.475.139/0001-33, com sede na Av. Pres. Wilson, 228, 13º andar, Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020 (<strong>“1STi”</strong> ou <strong>“Nós”</strong> ou <strong>“Nossos”</strong>), sobre o seu acesso e uso do site do Strytegy (https://www.strytegy.com), bem como qualquer aplicativo a ele relacionado (<strong>“Site”</strong>).
          </Text>
          <Text>
            <strong>1.2 O Site oferece os seguintes serviços:</strong> Plataforma digital para adicionar conteúdo às telas em paralelo a outras pessoas em tempo real, projetar e distribuir telas e conteúdo personalizado (<strong>“Serviços”</strong>).
          </Text>
          <Text>
            <strong>1.3 Obrigação de leitura e concordância prévia.</strong> Antes de acessar o Site e/ou os Serviços, Você deve ler, entender e concordar em estar vinculado a todo o conteúdo destes Termos de Uso e com a Política de Privacidade Strytegy disponível no Site (<strong>“Política de Privacidade”</strong>). Sendo assim, ao acessar o Site e os Serviços, Você assume que leu, entendeu e concorda em se vincular a todo o conteúdo destes Termos de Uso, assim como com o conteúdo da Política de Privacidade.
          </Text>
          <Text>
            <strong>1.4 Proibição de uso em caso de não concordância com os Termos de Uso.</strong> Se Você não concorda com os Termos de Uso, ainda que discorde de apenas parte dele, então Você está proibido de usar o Site e os Serviços e Você deve interromper o uso imediatamente. Recomendamos que Você salve uma cópia destes Termos de Uso para referência futura.
          </Text>
          <Text>
            <strong>1.5 Atualizações.</strong> Estes Termos de Uso poderão ser atualizados de tempos em tempos, para refletir mudanças em Nossos produtos, nas necessidades de Nossos usuários e/ou em Nossas prioridades de negócios. A versão atualizada destes Termos de Uso sempre estará disponível no Site, indicando no início do documento a data da última atualização. As atualizações dos Termos de Uso entram em vigor a partir de sua disponibilização no site. Você é responsável por acessar de tempos em tempos estes Termos de Uso para ficar informado sobre as atualizações. O uso contínuo do Site representa que Você aceitou tais alterações.
          </Text>
          <Text>
            <strong>1.5.1</strong> Se fizermos alterações substanciais nestes Termos de Uso e Você tiver se cadastrado em nosso Site, enviaremos um e-mail para Você para avisar que os Termos de Uso serão atualizados.
          </Text>
          <Text>
            <strong>1.6 País de origem dos usuários.</strong> O Site é direcionado principalmente para usuários residentes ou com sede no Brasil, porém pode ser acessado por pessoas que estejam em outras localidades. As informações fornecidas no Site não se destinam ao uso, por qualquer pessoa física ou pessoa jurídica, em qualquer jurisdição ou país onde tal uso seja contrário à lei ou regulamentação ou que Nos sujeitaria a qualquer requisito de registro dentro de tal jurisdição ou país ou atendimento de legislação de referido local.
          </Text>
          <Text>
            <strong>1.7 Idade mínima para uso.</strong> O Site é destinado a usuários com pelo menos 18 anos. Se Você tiver menos de 18 anos, o seu registro no Site e uso dos Serviços deverá ser realizado com a permissão e acompanhamento de seus responsáveis legais.
          </Text>
          <Text bold>2. Forma adequada de usar o Site e os Serviços</Text>
          <Text>
            <strong>2.1</strong> Você não pode acessar ou usar o Site para qualquer finalidade que não seja aquela para a qual disponibilizamos o Site e os Serviços.
          </Text>
          <Text>
            <strong>2.2</strong> Como usuário do Site, Você concorda e se obriga a não:
          </Text>
          <OrderedList type='a'>
            <ListItem>
              Recuperar sistematicamente dados ou outros conteúdos do Site para um banco de dados ou diretório de compilação sem permissão por escrito da 1STi;
            </ListItem>
            <ListItem>
              Fazer qualquer uso não autorizado do Site, incluindo coletar nomes de usuário e/ou endereços de e-mail dos usuários para enviar e-mails não solicitados ou criar contas de usuário sob falsos pretextos;
            </ListItem>
            <ListItem>Usar o Site para anunciar ou vender bens e serviços;</ListItem>
            <ListItem>
              Contornar, desativar ou interferir de outra forma com recursos relacionados à segurança do Site, incluindo recursos que impedem ou restringem o uso ou cópia de qualquer conteúdo ou impõem limitações sobre o uso;
            </ListItem>
            <ListItem>
              Enganar, fraudar ou enganar a Nós e/ou a outros usuários do Site, especialmente em qualquer tentativa de obter informações confidenciais de conta, como dados pessoais e senhas de usuário;
            </ListItem>
            <ListItem>
              Fazer uso indevido de Nossos serviços de suporte ou enviar falsas denúncias de abuso ou má conduta;
            </ListItem>
            <ListItem>
              Envolver-se em qualquer uso automatizado do sistema, como usar scripts para enviar comentários ou mensagens, ou usar qualquer mineração de dados, robôs ou ferramentas similares de coleta e extração de dados;
            </ListItem>
            <ListItem>
              Interferir, interromper ou criar uma sobrecarga indevida no Site ou nas redes e serviços conectados ao Site;
            </ListItem>
            <ListItem>
              Tentar se passar por outro usuário ou pessoa, ou usar o nome de usuário ou dados de acesso de outro usuário;
            </ListItem>
            <ListItem>
              Vender ou transferir seu perfil de usuário do Site;
            </ListItem>
            <ListItem>
              Usar qualquer informação obtida no Site para assediar, abusar ou prejudicar outra pessoa, ou para praticar qualquer ato ilegal ou imoral;
            </ListItem>
            <ListItem>
              Usar o Site ou Nosso conteúdo como parte de qualquer esforço para competir com a Strytegy, com a 1STi ou para criar produtos comerciais;
            </ListItem>
            <ListItem>
              Decifrar, descompilar, desmontar ou fazer engenharia reversa do Site, de qualquer um dos Serviços ou de qualquer dos softwares que compõem ou de alguma forma fazem parte do Site e/ou dos Serviços;
            </ListItem>
            <ListItem>
              Tentar acessar quaisquer partes do Site que Você esteja impedido de acessar;
            </ListItem>
            <ListItem>
              Assediar, irritar, intimidar ou ameaçar qualquer um de Nossos colaboradores ou outros usuários;
            </ListItem>
            <ListItem>
              Excluir avisos de direitos autorais ou de propriedades intelectuais de qualquer conteúdo do Site;
            </ListItem>
            <ListItem>
              Copiar ou adaptar os Serviços, o Site ou qualquer um de seus softwares, incluindo, mas não se limitando a Flash, PHP, HTML, JavaScript ou outro código;
            </ListItem>
            <ListItem>
              Carregar ou transmitir (ou tentar carregar ou transmitir) vírus, cavalos de tróia ou outro material que interfira no uso e aproveitamento ininterrupto do Site ou que possa prejudicar nossos usuários ou qualquer material que atue como um mecanismo passivo ou ativo de coleta ou transmissão de informações;
            </ListItem>
            <ListItem>
              Usar, iniciar ou se envolver em qualquer uso automatizado do sistema, como o uso de scripts para enviar comentários ou mensagens, robôs, scrapers, leitores offline ou ferramentas similares de coleta e extração de dados;
            </ListItem>
            <ListItem>
              Realizar qualquer ato que de alguma forma prejudique ou cause danos a Nós, aos Serviços, ao Site e/ou aos usuários do Site;
            </ListItem>
            <ListItem>
              Usar o Site de forma inconsistente com quaisquer leis ou regulamentações aplicáveis;
            </ListItem>
            <ListItem>
              Anunciar produtos ou serviços de forma vinculativa ao Site e/ou aos Serviços sem a Nossa autorização prévia e por escrito;
            </ListItem>
            <ListItem>
              Sugerir ou induzir terceiros a acreditar existir qualquer relação entre Você e Nós que não seja verídica.
            </ListItem>
          </OrderedList>
          <Text bold>3. Informações fornecidas por Você a Nós</Text>
          <Text>
            <strong>3.1</strong> Você declara e garante que:
          </Text>
          <OrderedList type='a'>
            <ListItem>
              Todas as informações cadastrais que Você fornecer a Nós serão verdadeiras, precisas, atuais e completas e relacionadas a Você e não a terceiros;
            </ListItem>
            <ListItem>
              Você atualizará essas informações prontamente sempre que elas sofrerem qualquer alteração ou estiverem incompletas ou incorretas;
            </ListItem>
            <ListItem>
              Você manterá sua senha confidencial e será responsável por todo o uso de sua senha e conta;</ListItem>
            <ListItem>
              Você tem capacidade legal e concorda em cumprir estes Termos de Uso;
            </ListItem>
            <ListItem>
              Caso Você seja menor de idade, o seu registro no Site e uso dos Serviços está sendo realizado com a permissão e acompanhamento de seus responsáveis legais; e
            </ListItem>
            <ListItem>
              Se Você estiver fazendo uso do Site e/ou dos Serviços em nome de pessoa jurídica, tem as autorizações legais necessárias para representar referida pessoa jurídica.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>3.2</strong> Se Você sabe ou suspeita que qualquer pessoa que não seja Você tenha conhecimento de suas informações de usuário e/ou senha, Você deve nos notificar prontamente, por meio de envio de e-mail para hey@strytegy.com.
          </Text>
          <Text>
            <strong>3.3</strong> Se Você fornecer qualquer informação que seja falsa, imprecisa, não atual ou incompleta, Nós podemos suspender ou encerrar a sua conta. Nós podemos, ainda, remover ou alterar o nome de usuário escolhido por Você se entendermos que esse nome de usuário é inapropriado.
          </Text>
          <Text>
            <strong>3.4</strong> Como parte da funcionalidade do Site, Você pode vincular sua conta no Site com contas que Você tenha em serviços de terceiros (<strong>“Conta de Terceiros”</strong>), ao fazer seu login no Site por meio de Conta de Terceiros. Caso Você deseje vincular sua Conta de Terceiros para realizar login no Site, Você declara e garante que tem o direito de divulgar suas informações de login da Conta de Terceiros para Nós sem que isso represente violação por Você de qualquer um dos termos e condições que regem o uso da Conta de Terceiros aplicável e sem nos obrigar a pagar quaisquer taxas ou nos obrigando a quaisquer limitações de uso impostas por tais provedores de serviços de terceiros.
          </Text>
          <Text>
            <strong>3.5</strong> Ao nos conceder dados de qualquer Conta de Terceiros, Você entende que esses dados poderão ser vinculados à sua conta no Site e que Nós (a) podemos disponibilizar (se aplicável) e armazenar qualquer informação que Você tenha nos fornecido a respeito de referida Conta de Terceiro para que esteja disponível dentro e através do Site através de sua conta; e (b) podemos enviar informações adicionais para (e receber informações adicionais de) sua Conta de Terceiros na medida em que Você vincular sua conta no Site à Conta de Terceiros.
          </Text>
          <Text>
            <strong>3.6</strong> Observe que, se uma Conta de Terceiros ou serviço associado a ela ficar indisponível ou nosso acesso a tal Conta de Terceiros for encerrado pelo prestador de serviços ao qual a Conta de Terceiros está vinculada, isso pode prejudicar ou inviabilizar o seu acesso ao Site e/ou aos Serviços, sem que Nós possamos ser responsabilizados por isso.
          </Text>
          <Text bold>4. Conteúdos incluídos por Você no Site e sobre o Site</Text>
          <Text>
            <strong>4.1</strong> Os conteúdos postados por Você nas telas do Site (projetos) poderão ser visualizados por todas as pessoas com as quais os projetos forem compartilhados. Ao publicar conteúdos no Site, Você garante que referido conteúdo não é imoral, ilegal ou de qualquer forma desrespeitoso, e que Você é o único responsável por qualquer consequência decorrente dos conteúdos publicados por Você, incluindo eventuais perdas e danos gerados a Nós.
          </Text>
          <Text>
            <strong>4.2</strong> Nós não somos responsáveis e não aceitamos nenhuma responsabilidade por qualquer conteúdo publicado por você no Site, incluindo qualquer conteúdo que contenha informações incorretas ou seja difamatório ou contrário às leis brasileiras. Nós não teremos acesso a qualquer conteúdo inserido por Nossos usuários nas telas dos projetos, e não aceitamos nenhuma obrigação de selecionar, editar ou monitorar qualquer conteúdo incluído por Nossos usuários no Site. As opiniões expressas por outros usuários em telas dos projetos no Site não representam nossas opiniões ou valores.
          </Text>
          <Text>
            <strong>4.3</strong> Eventualmente, Você poderá enviar feedbacks públicos para Nós ou sobre Nós. Você entende e concorda que esses feedbacks poderão ser visualizados por outras pessoas e que elas podem ser capazes de ver que esses comentários foram postados por Você.
          </Text>
          <Text italic>
            <strong>4.4</strong> Você concorda ainda que podemos usar seus feedbacks e comentários públicos para quaisquer outros fins e combinar seus comentários e feedbacks com outros conteúdos, sem precisar necessariamente atribuí-los a Você (mas podendo o fazer).
          </Text>
          <Text bold>5. Nosso Conteúdo</Text>
          <Text>
            <strong>5.1</strong> A menos que indicado de outra forma, o Site e os Serviços, incluindo código-fonte, bancos de dados, funcionalidade, software, designs de sites, áudio, vídeo, texto, fotografias, imagens e gráficos no Site (<strong>“Nosso Conteúdo”</strong>) são de propriedade ou licenciados para nós, e são protegidos pelas leis de propriedade intelectual.
          </Text>
          <Text>
            <strong>5.2</strong> Exceto conforme expressamente fornecido nestes Termos de Uso, nenhuma parte do Site, Serviços ou Nosso Conteúdo pode ser copiada, reproduzida, agregada, republicada, postada, exibida publicamente, codificada, traduzida, transmitida, distribuída, vendida, licenciada ou explorada de qualquer forma para qualquer finalidade comercial, sem nossa permissão prévia e por escrito.
          </Text>
          <Text>
            <strong>5.3</strong> Desde que Você seja elegível para usar o Site, Você tem uma licença limitada para acessar e usar o Site e Nosso Conteúdo e para baixar ou imprimir uma cópia de qualquer parte do Nosso Conteúdo ao qual Você tenha acessado adequadamente apenas para seu uso pessoal e não comercial.
          </Text>
          <Text>
            <strong>5.4</strong> Você está terminantemente proibido de: (a) tentar obter acesso não autorizado ao Site ou a quaisquer redes, servidores ou sistemas de computador conectados ao Site; e (b) fazer, para qualquer finalidade (incluindo correção de erros), quaisquer modificações, adaptações, adições e/ou melhorias no Site, nos Serviços ou no Nosso Conteúdo, incluindo modificações em arquivos ou cópias digitais que Você pode ter feito download do Site.
          </Text>
          <Text>
            <strong>5.5</strong> Nós nos comprometemos a: (a) desenvolver o Site, os Serviços e Nosso Conteúdo com habilidade e cuidado esperados; e (b) usar software padrão de detecção de vírus para tentar bloquear o upload de conteúdo para o Site que contenha vírus. Porém, embora empenhemos nossos melhores esforços, não nos responsabilizamos por atos realizados por terceiros com a intenção de prejudicar usuários do Site ou obter vantagens sobre eles.
          </Text>
          <Text bold>6. Gerenciamento do Site</Text>
          <Text>
            <strong>6.1</strong> Reservamo-nos o direito a nosso exclusivo critério, de: (a) monitorar o Site por violações destes Termos de Uso; (b) tomar as medidas legais cabíveis contra qualquer pessoa que viole as leis aplicáveis ou estes Termos de Uso; (c) recusar a inserção de conteúdos que sejam contrários aos Termos de Uso, bem como restringir, suspender ou excluir definitivamente o acesso de qualquer usuário do Site que esteja violando estes Termos de Uso e/ou a legislação aplicável; e (d) gerenciar o Site de forma a proteger nossos direitos e propriedades e facilitar o bom funcionamento do Site e dos Serviços.
          </Text>
          <Text>
            <strong>6.2</strong> Embora empreguemos os nossos melhores esforços para isso, não garantimos que o Site estará seguro ou livre de bugs ou vírus decorrentes da ação de terceiros.
          </Text>
          <Text>
            <strong>6.3</strong> Você é responsável por configurar sua tecnologia da informação, programas de computador e plataforma para acessar o Site e Você deve usar seu próprio software de proteção contra vírus.
          </Text>
          <Text bold>7. Modificações e disponibilidade do Site e dos Serviços</Text>
          <Text>
            <strong>7.1</strong> Reservamo-nos o direito de alterar, modificar ou remover qualquer conteúdo do Site a qualquer momento ou por qualquer razão a nosso exclusivo critério, sem aviso prévio. Reservamo-nos também o direito de modificar ou descontinuar todos ou parte dos Serviços sem aviso prévio a qualquer momento.
          </Text>
          <Text>
            <strong>7.2</strong> Não podemos garantir que o Site e os Serviços estarão disponíveis o tempo todo. Podemos sofrer problemas técnicos com hardware, software ou outros problemas ou necessidade de realizar manutenções no Site ou Serviços, resultando em interrupções, atrasos ou erros. Você concorda que não temos qualquer responsabilidade por qualquer perda, dano ou inconveniência causado por sua impossibilidade de acessar ou usar o Site ou Serviços durante qualquer tempo de inatividade ou descontinuação do Site ou Serviços. Não somos obrigados a manter e oferecer suporte referente ao Site ou Serviços ou fornecer quaisquer correções, atualizações ou lançamentos.
          </Text>
          <Text>
            <strong>7.3</strong> Pode haver informações no Site que contenham erros tipográficos, imprecisões ou omissões que possam estar relacionadas aos Serviços, incluindo descrições, disponibilidade e outras informações. Reservamo-nos o direito de corrigir quaisquer erros, imprecisões ou omissões e alterar ou atualizar as informações a qualquer momento, sem aviso prévio.
          </Text>
          <Text bold>8. Isenção de responsabilidade/Limitação da Responsabilidade</Text>
          <Text>
            <strong>8.1</strong> O Site e os Serviços são fornecidos no estado em que se encontram disponibilizados (“as-is” e “as-available”). Você concorda que o uso do Site e/ou dos Serviços será por sua conta e risco, exceto conforme expressamente estabelecido nestes Termos de Uso e na legislação brasileira. Nós não assumimos qualquer garantia e/ou compromissos em relação ao Site e aos Serviços além do expressamente previsto nestes Termos de Uso, Nos eximindo de qualquer garantia e/ou compromisso implícito (incluindo aqueles decorrentes de usos e costumes), nas medidas e limites da legislação aplicável.
          </Text>
          <Text>
            <strong>8.2</strong> Nós não fazemos garantias ou representações sobre a exatidão ou completude do conteúdo do Site e não somos responsáveis por quaisquer: (a) erros ou omissões de conteúdo; (b) qualquer acesso não autorizado ou uso de nossos servidores; e/ou (c) quaisquer bugs, vírus, cavalos de tróia ou semelhantes que possam ser transmitidos para ou através do Site por terceiros. Nós não seremos responsáveis por qualquer atraso ou descumprimento de nossas obrigações nos Termos de Uso se tal atraso ou falha for causado por um evento além do nosso controle razoável.
          </Text>
          <Text bold>9. Prazo e Término</Text>
          <Text>
            <strong>9.1</strong> Estes Termos de Uso permanecerão em pleno vigor e efeito enquanto Você usar o Site ou os Serviços ou mantiver ativo o seu usuário no Site. Você pode encerrar seu uso do Site e dos Serviços ou a sua conta de usuário a qualquer momento, por qualquer motivo, seguindo as instruções para encerrar contas de usuário nas configurações da sua conta, se disponível, ou entrando em contato conosco em hey@strytegy.com.
          </Text>
          <Text>
            <strong>9.2</strong> Sem limitação a qualquer outra disposição destes Termos de Uso, Nós nos reservamos o direito de, a nosso exclusivo critério e sem aviso prévio, negar o acesso e o uso do Site e dos Serviços (incluindo o bloqueio de determinados endereços IP), a qualquer pessoa por qualquer razão, incluindo sem limitação por violação de qualquer representação, garantia ou obrigação contido nestes Termos de Uso ou de qualquer lei ou regulamentação aplicável.
          </Text>
          <Text>
            <strong>9.3</strong> Se Nós determinarmos, a nosso exclusivo critério, que a forma como Você faz uso do Site e/ou dos Serviços está violando estes Termos de Uso, a Política de Privacidade ou de qualquer lei ou regulamentação aplicável, poderemos encerrar seu uso ou acesso ao Site e aos Serviços ou excluir seu perfil e qualquer conteúdo ou informação que Você postou a qualquer momento, sem aviso prévio, a nosso exclusivo critério.
          </Text>
          <Text>
            <strong>9.4</strong> Se encerrarmos ou suspendermos a sua conta por qualquer motivo estabelecido nesta seção, Você está proibido de registrar e criar outra conta em seu nome ou com um nome falso ou em nome de qualquer terceiro, mesmo que Você tenha poderes de representação de referido terceiro. Além de encerrar ou suspender sua conta, reservamo-nos o direito de tomar as medidas legais cabíveis, inclusive sem limitação, buscando reparação civil, criminal e cautelar.
          </Text>
          <Text bold>10. Disposições gerais</Text>
          <Text>
            <strong>10.1</strong> Visitar o Site, enviar-nos ou receber de Nós e-mails e preencher formulários online constituem comunicações eletrônicas. Você concorda em receber comunicações eletrônicas e concorda que todos os acordos, avisos, divulgações e outras comunicações que fornecemos a Você eletronicamente, via e-mail e no Site, satisfazem qualquer exigência legal de que tal comunicação esteja por escrito.
          </Text>
          <Text>
            <strong>10.2</strong> Você concorda com o uso de assinaturas eletrônicas, contratos, pedidos e outros registros e com a entrega eletrônica de avisos, políticas e registros de transações iniciadas ou concluídas por Nós ou através do Site.
          </Text>
          <Text>
            <strong>10.3</strong> Strytegy é uma marca comercial da 1STi. Você não tem permissão para usá-la sem a nossa aprovação.
          </Text>
          <Text>
            <strong>10.4</strong> Estes Termos de Uso e quaisquer políticas ou regras relativas ao Site e/ou aos Serviços publicadas por Nós no Site constituem todo o acordo e entendimento entre Você e Nós.
          </Text>
          <Text>
            <strong>10.5</strong> Nossa falha em exercer ou impor qualquer direito ou disposição destes Termos de Uso não caracterizará a renúncia a tal direito ou disposição.
          </Text>
          <Text>
            <strong>10.6</strong> Se qualquer disposição ou parte de uma disposição destes Termos de Uso for considerada ilegal, nula ou inexequível, essa disposição ou parte da disposição será desconsiderada e não afetará a validade e a aplicabilidade de quaisquer disposições remanescentes.
          </Text>
          <Text>
            <strong>10.7</strong> Caso Você seja um usuário pessoa física residente no Brasil, eventuais dúvidas ou conflitos decorrentes destes Termos de Uso serão resolvidos no Foro da sua residência. Caso Você seja um usuário pessoa física residente fora do Brasil ou um usuário pessoa jurídica, fica eleito o Foto da Comarca do Rio de Janeiro/RJ para resolver eventuais dúvidas ou conflitos decorrentes destes Termos de Uso, com renúncia a qualquer outro, por mais privilegiado que seja. Em qualquer hipótese, estes Termos de Uso serão regulados exclusivamente pela legislação brasileira.
          </Text>
          <Text>
            <strong>10.8</strong> Para realizar uma reclamação ou tirar dúvidas sobre o Site e/ou os Serviços, entre em contato conosco pelo endereço de e-mail hey@strytegy.com ou enviando correspondência para:
          </Text>
          <TextFooter>ISTI Soluções</TextFooter>
          <TextFooter>Av. Pres. Wilson, 288, 13° andar</TextFooter>
          <TextFooter>Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020</TextFooter>
        </Content>
      </Container>
    </Wrapper>
  </Overlay>
);

export default TermsOfServicePT;
