/* eslint-disable */
// Libs
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Images
import closeIcon from '../assets/images/close.svg';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// style properties
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #0c153480;
  animation: ${OpacityAnimation} 0.2s;
  z-index: 2;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30.625rem;
  border-radius: 0.625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 .875rem #00000014;
  overflow: hidden;

  @media (max-width: 480px) {
    margin: 0;
    width: 95%;
    justify-content: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled.img`
  width: 1rem;
`;

const Container = styled.div`
  padding: 2.8125rem;
  width: 100%;

  @media (max-width: 480px) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  margin: 0 0 2.625rem;
  font: 600 2.1875rem 'Paralucent', sans-serif;
  color: #22254B;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
  max-height: 30.5rem;
  border-radius: .3125rem;
  border: .0625rem solid #22254B0D;
  background: #CAAAE50D;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: .375rem;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0.2rem;
    border-radius: .9375rem;
    background: #4A180526;
  }

  @media (max-width: 480px) {
    max-height: 26.5rem;
  }
`;

const Text = styled.p`
  margin: ${(props) => props.bold && '0'};
  width: 95%;
  font: ${(props) => (props.bold ? '600' : '200')} ${(props) => (props.bold ? '1rem' : '.85rem')} 'Soleto', sans-serif;
  color: ${(props) => (props.purple ? '#5321C4' : '#22254B')};
  font-style: ${(props) => props.italic && 'italic'};
  user-select: none;
  line-height: 1.375rem;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding-left: 6%;
`;

const ListItem = styled.li`
  margin: 0.25rem 0;
  width: 100%;
  font: 300 .625rem 'Soleto', sans-serif;
  color: #22254B;
  line-height: 1.375rem;
  user-select: none;
`;

const TextFooter = styled(Text)`
  font-size: 0.85rem;
  margin: 0;
`;

const TermsOfServiceEN = ({ closeFunction }) => (
  <Overlay>
    <Wrapper>
      <CloseButton onClick={closeFunction}>
        <CloseIcon src={closeIcon} />
      </CloseButton>
      <Container>
        <Title bold>Terms of Use</Title>
        <Content>
          <Text bold>Strytegy</Text>
          <Text purple>LAST UPDATED: July 6, 2021</Text>
          <Text bold>1. Compliance with Terms of Use and Initial Information</Text>
          <Text>
            <strong>1.1</strong> These Terms of Use constitute a legally binding agreement made between you, an individual user or a legal entity, through your legal representative (<strong>“You”</strong>), and ISTI Soluções em Tecnologia Ltda, registered with the CNPJ number 13.475.139/0001-33, headquartered at Av. Pres. Wilson, 228, 13o andar, Centro, Rio de Janeiro/RJ, Brazil, CEP 20030-020 (<strong>“1STi”</strong>, <strong>“we”</strong>, <strong>“us”</strong>, or <strong>“our”</strong>), regarding your access to and use of the Strytegy website (https://www.strytegy.com) as well as any related applications (<strong>“Site”</strong>).
          </Text>
          <Text>
            <strong>1.2 The Site provides the following services:</strong> Digital platform to add content to canvases in parallel with other people in real time, design and distribute customized canvases and content (<strong>“Services”</strong>).
          </Text>
          <Text>
            <strong>1.3 Obligation of reading and prior agreement.</strong> Before accessing the Site and/or the Services, You must read, understand and agree to be bound by the entire content of these Terms of Use and the Strytegy Privacy Policy available on the Site (<strong>“Privacy Policy”</strong>). Therefore, by accessing the Site and the Services, You acknowledge that You have read, understood and agree to be bound by the entire content of these Terms of Use, as well as the content of the Privacy Policy.
          </Text>
          <Text>
            <strong>1.4 Prohibition of use in case of non-compliance with the Terms of Use.</strong> If You do not agree with the Terms of Use, even if You only disagree with part of them, then You are prohibited from using the Site and the Services and You must discontinue use immediately. We recommend that You save a copy of these Terms of Use for future reference.
          </Text>
          <Text>
            <strong>1.5 Updates</strong> These Terms of Use may be updated from time to time to reflect changes in Our products, the needs of Our users and/or Our business priorities. The updated version of these Terms of Use will always be available on the Site, with the date of the last update indicated at the beginning of the document. Updates to the Terms of Use are effective as of their availability on the Site. You are responsible for accessing these Terms of Use from time to time to stay informed of updates. Your continued use of the Site represents that You have accepted such changes.
          </Text>
          <Text>
            <strong>1.5.1</strong> If we make substantial changes to these Terms of Use and You have registered on our Site, we will email You to let You know that the Terms of Use will be updated.
          </Text>
          <Text>
            <strong>1.6 Users native country.</strong> The Site is primarily intended for users residing or based in Brazil, but can be accessed by people located in other locations. The information provided on the Site is not intended for use by any individual or legal entity in any jurisdiction or country where such use would be contrary to law or regulation or which would subject Us to any registration requirement within such jurisdiction or country or compliance of legislation of that place.
          </Text>
          <Text>
            <strong>1.7 Minimum age for use.</strong> The Site is intended for users who are at least 18 years old. If You are under 18 years of age, Your registration on the Site and use of the Services must be carried out with the permission and supervision of your legal guardians.
          </Text>
          <Text bold>2. Proper way of using the Site and Services</Text>
          <Text>
            <strong>2.1</strong> You may not access or use the Site for any purpose other than that for which we make the Site and Services available.
          </Text>
          <Text>
            <strong>2.2</strong> As a user of the Site, You agree and oblige yourself not to:
          </Text>
          <OrderedList type='a'>
            <ListItem>
              Systematically retrieve data or other content from the Site to a database or compilation directory without written permission from 1STi;
            </ListItem>
            <ListItem>
              Make any unauthorized use of the Site, including collecting usernames and/or email addresses from users to send unsolicited emails or create user accounts under false pretenses;
            </ListItem>
            <ListItem>
              Use the Site to advertise or sell goods and services;
            </ListItem>
            <ListItem>
              Bypass, disable or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any content or impose limitations on use;
            </ListItem>
            <ListItem>
              Deceive, defraud or deceive Us and/or other users of the Site, especially in any attempt to obtain confidential account information such as personal data and user passwords;
            </ListItem>
            <ListItem>
              Misuse of our support services or submit false reports of abuse or misconduct;
            </ListItem>
            <ListItem>
              Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots or similar data collection and extraction tools;
            </ListItem>
            <ListItem>
              Interfere with, interrupt or create an undue burden on the Site or on the networks and services connected to the Site;
            </ListItem>
            <ListItem>
              Trying to impersonate another user or person, or using another user's username or access data;
            </ListItem>
            <ListItem>
              Sell or transfer your user profile from the Site;
            </ListItem>
            <ListItem>
              Use any information obtained on the Site to harass, abuse or harm another person, or to perform any illegal or immoral act;
            </ListItem>
            <ListItem>
              Use the Site or Our content as part of any effort to compete with Strytegy, 1STi or to create commercial products;
            </ListItem>
            <ListItem>
              Decrypt, decompile, disassemble or perform reverse engineer of the Site, any of the Services or any of the software that make up, or in any way form, part of the Site and/or the Services;
            </ListItem>
            <ListItem>
              Attempt to access any parts of the Site that you are restricted from accessing;
            </ListItem>
            <ListItem>
              Harass, irritate, intimidate or threaten any of Our employees or other users;
            </ListItem>
            <ListItem>
              Delete copyright or intellectual property notices from any content on the Site;
            </ListItem>
            <ListItem>
              Copy or adapt the Services, the Site or any of its software, including but not limited to Flash, PHP, HTML, JavaScript or other code;
            </ListItem>
            <ListItem>
              Upload or transmit (or attempt to upload or transmit) viruses, Trojan horses or other material that interferes with the uninterrupted use and enjoyment of the Site or that may harm our users or any material that acts as a passive or active information collection or transmission mechanism;
            </ListItem>
            <ListItem>
              Use, initiate or engage in any automated use of the system, such as using scripts to send comments or messages, robots, scrapers, offline readers or similar data gathering and extraction tools;
            </ListItem>
            <ListItem>
              Perform any act that in any way harms or causes damage to Us, the Services, the Site and/or the users of the Site;
            </ListItem>
            <ListItem>
              Use the Site in a manner inconsistent with any applicable laws or regulations;
            </ListItem>
            <ListItem>
              AAdvertise products or services bindingly to the Site and/or the Services without Our prior written consent;
            </ListItem>
            <ListItem>
              Suggest or induce others to believe that there is any relationship between You and Us that is not true.
            </ListItem>
          </OrderedList>
          <Text bold>3. Information You provide to Us</Text>
          <Text>
            <strong>3.1</strong> You represent and warrant that:
          </Text>
          <OrderedList type='a'>
            <ListItem>
              All registration information You provide to Us will be true, accurate, current and complete and related to You and not related to any third parties;
            </ListItem>
            <ListItem>
              You will promptly update this information whenever it changes or is incomplete or incorrect;
            </ListItem>
            <ListItem>
              You will keep your password confidential and will be responsible for all use of your password and account;
            </ListItem>
            <ListItem>
              You have legal capacity and agree to abide by these Terms of Use;
            </ListItem>
            <ListItem>
              If you are a minor, your registration on the Site and use of the Services is being carried out with the permission and supervision of your legal guardians; and
            </ListItem>
            <ListItem>
              If You are using the Site and/or the Services on behalf of a legal entity, You have the necessary legal authorizations to represent that legal entity.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>3.2</strong> If You know or suspect that anyone other than You is aware of Your username and/or password information, You must promptly notify us by sending an email to hey@strytegy.com.
          </Text>
          <Text>
            <strong>3.3</strong> If You provide any information that is false, inaccurate, not current or incomplete, We may suspend or terminate your account. We may also remove or change Your chosen username if we believe that such username is inappropriate.
          </Text>
          <Text>
            <strong>3.4</strong> As part of the functionality of the Site, You may link Your account on the Site with accounts You have on third party services (<strong>“Third Party Account”</strong>) by logging in to the Site through a Third Party Account. If You wish to link Your Third Party Account to log in to the Site, You represent and warrant that You have the right to disclose Your Third Party Account login information to Us without breach by You of any of the terms and conditions that govern your use of the applicable Third Party Account, and without obliging us to pay any fees or binding us to any usage limitations imposed by such third party service providers.
          </Text>
          <Text>
            <strong>3.5</strong> By providing Us with data from any Third Party Account, You understand that such data may be linked to Your Account on the Site and that We (a) may make available (if applicable) and store any information You have provided to Us regarding such Third Party Account to be available on and through the Site via your account; and (b) we may send additional information to (and receive additional information from) Your Third Party Account as You link Your account on the Site to the Third Party Account.
          </Text>
          <Text>
            <strong>3.6</strong> Please note that if a Third Party Account, or a service associated to it, becomes unavailable or our access to such Third Party Account is terminated by the service provider towhich the Third Party Account is linked, this may impair or render your access to the Site unfeasible and /or the Services, without We being responsible for it.
          </Text>
          <Text bold>4. Content included by You on the Site and about the Site</Text>
          <Text>
            <strong>4.1</strong> The content posted by You on the Site's screens (projects) may be viewed by all the people with whom the projects are shared. By posting content on the Site, You warrant that such content is not immoral, illegal or in any way disrespectful, and that You are solely responsible for any consequences arising from the content posted by You, including any losses and damages generated by Us.
          </Text>
          <Text>
            <strong>4.2</strong> We are not responsible and do not accept any responsibility for any content posted by you on the Site, including any content that contains incorrect information or is defamatory or contrary to any applicable law. We will not have access to any content posted by Our users on project screens, and we do not accept any obligation to select, edit or monitor any content posted by Our users on the Site. The opinions expressed by other users in project screens on the Site do not represent our opinions or values.
          </Text>
          <Text>
            <strong>4.3</strong> Eventually, You may submit public feedback to Us or about Us. You understand and agree that such feedback may be viewed by others and that they may be able to see that such comments have been posted by You.
          </Text>
          <Text italic>
            <strong>4.4</strong> You further agree that We may use your feedbacks and public comments for any other purpose and combine your comments and feedbacks with other content without necessarily having to attribute them to You (but We may do it).
          </Text>
          <Text bold>5. Our Content</Text>
          <Text>
            <strong>5.1</strong> Unless otherwise indicated, the Site and the Services, including source code, databases, functionality, software, website designs, audio, video, text, photographs, images and graphics on the Site (<strong>"Our Content"</strong>) are owned by or licensed to us, and are protected by intellectual property laws.
          </Text>
          <Text>
            <strong>5.2</strong> Except as expressly provided in these Terms of Use, no part of the Site, Services or Our Content may be copied, reproduced, aggregated, republished, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed or otherwise exploited for any commercial purpose without our prior written permission.
          </Text>
          <Text>
            <strong>5.3</strong> Provided that You are eligible to use the Site, You have a limited license to access and use the Site and Our Content and to download or print a copy of any portion of Our Content that You have properly accessed for your personal use only and non-commercial.
          </Text>
          <Text>
            <strong>5.4</strong> You are strictly prohibited from: (a) attempting to obtain unauthorized access to the Site or any networks, servers or computer systems connected to the Site; and (b) make, for any purpose (including error correction), any modifications, adaptations, additions and/or improvements to the Site, Services or Our Content, including modifications to files or digital copies You may have downloaded from the Site.
          </Text>
          <Text>
            <strong>5.5</strong> We are committed to: (a) develop the Site, Services and Our Content with the skill and care expected; and (b) use standard virus detection software to attempt to block content that contains viruses from being uploaded to the Site. However, although we make our best efforts, we are not responsible for acts performed by third parties with the intention of harming users of the Site or taking advantage of them.
          </Text>
          <Text bold>6. Site Management</Text>
          <Text>
            <strong>6.1</strong> We reserve the right in our sole discretion to: (a) monitor the Site for violations of these Terms of Use; (b) take reasonable legal action against any person who violates applicable laws or these Terms of Use; (c) refuse the insertion of content that is contrary to the Terms of Use, as well as restrict, suspend or definitively exclude the access of any user to the Site who is violating these Terms of Use and/or applicable law; and (d) manage the Site in a way that protects our rights and property and facilitates the proper functioning of the Site and Services.
          </Text>
          <Text>
            <strong>6.2</strong> Although we use our best efforts to do so, we do not guarantee that the Site will be safe or free from bugs or viruses arising from the actions of third parties.
          </Text>
          <Text>
            <strong>6.3</strong> You are responsible for configuring your information technology, computer programs and platform to access the Site and You must use your own virus protection software.
          </Text>
          <Text bold>7. Modifications to and availability of the Site</Text>
          <Text>
            <strong>7.1</strong> We reserve the right to change, modify or remove any content on the Site at any time or for any reason in our sole discretion, without prior notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.
          </Text>
          <Text>
            <strong>7.2</strong> We cannot guarantee that the Site and Services will be available at all times. We may experience technical problems with hardware, software or other problems or need to perform maintenance on the Site or Services, resulting in interruptions, delays or errors. You agree that we have no responsibility for any loss, damage or inconvenience caused by your inability to access or use the Site or Services during any downtime or discontinuation of the Site or Services. We are not obligated to maintain and support the Site or Services or provide any corrections, updates or releases.
          </Text>
          <Text>
            <strong>7.3</strong> There may be information on the Site that contains typographical errors, inaccuracies or omissions that may be related to the Services, including descriptions, availability and other information. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time without notice.
          </Text>
          <Text bold>8. Disclaimer/Limitation of Liability</Text>
          <Text>
            <strong>8.1</strong> The Site and the Services are provided in the state in which they are made available (“as-is” and “as-available”). You agree that the use of the Site and/or the Services will be at your own risk and expense, except as expressly set out in these Terms of Use and under the Brazilian law. We do not take on any warranty and/or commitments in relation to the Site and the Services beyond what is expressly provided for in these Terms of Use, disclaiming us ofany implied warranty and/or commitment (including those arising from uses and customs), in the measures and limits of the applicable law.
          </Text>
          <Text>
            <strong>8.2</strong> We make no warranties or representations about the accuracy or completeness of the Site's content and are not responsible for any: (a) errors or omissions in content; (b) any unauthorized access to or use of our servers; and/or (c) any bugs, viruses, Trojan horses or similar that may be transmitted to or through the Site by third parties. We will not be liable for any delay or failure to comply with our obligations under the Terms of Use if such delay or failure is caused by an event beyond our reasonable control.
          </Text>
          <Text bold>9. Term and Termination</Text>
          <Text>
            <strong>9.1</strong> These Terms of Use shall remain in full force and effect for as long as You use the Site or the Services or keep your user active on the Site. You may terminate your use of the Site and Services or your user account at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at hey@strytegy.com.
          </Text>
          <Text>
            <strong>9.2</strong> Without limitation to any other provision of these Terms of Use, We reserve the right, in our sole discretion and without notice, to deny access to and use of the Site and Services (including blocking certain IP addresses), to any person for any reason, including without limitation for breach of any representation, warranty or obligation contained in these Terms of Use or any applicable law or regulation.
          </Text>
          <Text>
            <strong>9.3</strong> If We determine, in Our sole discretion, that Your use of the Site and/or the Services is in violation of these Terms of Use, the Privacy Policy or any applicable law or regulation, We may terminate Your use of or access to the Site and the Services or delete Your profile and any content or information You have posted at any time, without notice, in our sole discretion.
          </Text>
          <Text>
            <strong>9.4</strong> SIf We terminate or suspend Your account for any reason set out in this section, You are prohibited from registering and creating another account in Your name or under a false name or in the name of any third party, even if You have powers of representation from such third party. In addition to closing or suspending your account, we reserve the right to take appropriate legal action, even without limitation, seeking civil, criminal and injunctive relief.
          </Text>
          <Text bold>10. General</Text>
          <Text>
            <strong>10.1</strong> Visiting the Site, sending or receiving emails from Us and filling out online forms constitute electronic communications. You agree to receive electronic communications and agree that all agreements, notices, disclosures and other communications we provide to You electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.
          </Text>
          <Text>
            <strong>10.2</strong> You hereby agree to the use of electronic signatures, contracts, orders and other records and to the electronic delivery of notices, policies and records of transactions initiated or completed by Us or through the Site.
          </Text>
          <Text>
            <strong>10.3</strong> Strytegy is a trademark of 1STi. You are not allowed to use it without our approval.
          </Text>
          <Text>
            <strong>10.4</strong> These Terms of Use and any policies or rules relating to the Site and/or the Services published by Us on the Site constitute the entire agreement and understanding between You and Us.
          </Text>
          <Text>
            <strong>10.5</strong> Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision.
          </Text>
          <Text>
            <strong>10.6</strong> If any provision or part of a provision of these Terms of Use is found to be illegal, null and void or unenforceable, that provision or part of the provision will be disregarded and will not affect the validity and enforceability of any remaining provisions.
          </Text>
          <Text>
            <strong>10.7</strong> If you are an individual user residing in Brazil, any doubts or conflicts arising from these Terms of Use will be resolved in the jurisdiction of your residence. If you are an individual user residing outside of Brazil or a business user, the jurisdiction of Rio de Janeiro/RJ, Brazil, is elected to resolve any doubts or conflicts arising from these Terms of Use, waiving any other, however privileged it may be. In any event, these Terms of Use will be governed exclusively by Brazilian law.
          </Text>
          <Text>
            <strong>10.8</strong> To make a complaint or ask any questions about the Site and/or the Services, please contact us at the email address hey@strytegy.com or send a letter to:
          </Text>
          <TextFooter>ISTI Soluções</TextFooter>
          <TextFooter>Av. Pres. Wilson - Centro, Rio de Janeiro - RJ, 20030-020, Brazil</TextFooter>
          <TextFooter>288 - 13° andar</TextFooter>
          <TextFooter>Rio de Janeiro, Brazil, Rio de Janeiro 20030-020</TextFooter>
          <TextFooter>Brazil</TextFooter>
        </Content>
      </Container>
    </Wrapper>
  </Overlay>
);

export default TermsOfServiceEN;
