// Libs
import React, { useState } from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

// in your cookie banner
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent from 'react-cookie-consent';

// Components
import Header from './Header';

// Reset
import '../utils/layout.css';
import Footer from './Footer';
import TermsOfServicePT from './TermsOfServicePT';
import PrivacyPolicyPT from './PrivacyPolicyPT';
import TermsOfServiceEN from './TermsOfServiceEN';
import PrivacyPolicyEN from './PrivacyPolicyEN';

// Styles
const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;
  overflow-x: hidden;

  @media (max-width: 480px) {
    padding-top: 4rem;
  }
`;

const CookieBox = styled.p`
  margin: auto;
  font: 400 .875rem 'Soleto', sans-serif;
  line-height: 1.125rem;
`;

const dict = {
  en: {
    'cookie-title': 'This website uses cookies to improve your experience.',
    'cookie-accept': 'Accept',
  },
  'pt-BR': {
    'cookie-title': 'Esse website usa cookies para melhorar sua experiência',
    'cookie-accept': 'Aceitar',
  },
};

const Layout = ({
  children,
  language,
  isMobile,
  type,
  isOpenCanvas,
  widthSize,
  isBlue
}) => {
  const [isTerms, setTerms] = useState(false);
  const [isPrivacy, setPrivacy] = useState(false);

  I18n.setLanguage(language === 'pt-BR' ? 'pt-BR' : 'en');
  I18n.putVocabularies(dict);

  const location = useLocation();

  const getMargin = () => {
    if (type !== 'home') return '2rem';

    if (widthSize <= 768) return '2rem';

    if (isOpenCanvas && type === 'home') return '16rem';

    if (!isOpenCanvas && type === 'home') return '5rem';

    return '3rem';
  }

  const renderCookieConsent = () => {
    if (typeof document !== 'undefined') {
      const left = document.getElementById('header-box')?.offsetLeft;
      const width = isMobile ? '20rem' : '22rem';

      const CookieBoxStyle = {
        position: 'absolute',
        left: `${left}px`,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        width,
        height: '3.75rem',
        borderRadius: '.25rem',
        border: '2px solid #22254B',
        backgroundColor: '#ffffff99',
        color: '#000',
        margin: `${getMargin()} 0`,
        boxShadow: '0px 0px 2px #ffffff',
        transition: 'margin .3s',
        zIndex: 3,
      };

      const CookieContentStyle = {
        flex: 'initial',
        width: 'calc(100% - 5rem)',
        height: '100%',
        margin: '0',
        padding: '.75rem 1rem',
        borderRadius: '.25rem 0 0 .25rem',
        backgroundColor: '#ffffff99',
        backdropFilter: 'blur(10px)',
      };

      const CookieButtonStyle = {
        width: '5rem',
        height: '3.5rem',
        margin: '0',
        padding: '6px 10px 5px',
        backgroundColor: '#22254B',
        font: '400 1rem "Soleto", sans-serif',
        color: '#fff',
      };

      return (
        <CookieConsent
          enableDeclineButton={false}
          onAccept={() => initializeAndTrack(location)}
          location="bottom"
          buttonText={I18n.get('cookie-accept')}
          cookieName="gatsby-gdpr-google-analytics"
          style={CookieBoxStyle}
          contentStyle={CookieContentStyle}
          buttonStyle={CookieButtonStyle}
        >
          <CookieBox>
            {I18n.get('cookie-title')}
          </CookieBox>
        </CookieConsent>
      );
    }
  }

  return (
    <Container
      id='container-page'
    >
      <Header
        language={language}
        type={type}
        isMobile={isMobile}
      />
      <main>{children}</main>
      <Footer
        type={type}
        language={language}
        isMobile={isMobile}
        setTerms={setTerms}
        setPrivacy={setPrivacy}
        isBlue={isBlue}
      />
      {renderCookieConsent()}
      {isTerms && (
        language === 'pt-BR' ? (
          <TermsOfServicePT
            closeFunction={() => setTerms(false)}
          />
        ) : (
          <TermsOfServiceEN
            closeFunction={() => setTerms(false)}
          />
        ))
      }
      {isPrivacy && (
        language === 'pt-BR' ? (
          <PrivacyPolicyPT
            closeFunction={() => setPrivacy(false)}
          />
        ) : (
          <PrivacyPolicyEN
            closeFunction={() => setPrivacy(false)}
          />
        ))
      }
    </Container>
  )
};

export default Layout;
