/* eslint-disable */
// Libs
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Images
import closeIcon from '../assets/images/close.svg';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// style properties
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #0c153480;
  animation: ${OpacityAnimation} 0.2s;
  z-index: 2;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30.625rem;
  border-radius: 0.625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 .875rem #00000014;
  overflow: hidden;

  @media (max-width: 480px) {
    margin: 0;
    width: 95%;
    justify-content: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled.img`
  width: 1rem;
`;

const Container = styled.div`
  padding: 2.8125rem;
  width: 100%;

  @media (max-width: 480px) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  margin: 0 0 2.625rem;
  font: 600 2.1875rem 'Paralucent', sans-serif;
  color: #22254B;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
  max-height: 30.5rem;
  border-radius: .3125rem;
  border: .0625rem solid #22254B0D;
  background: #CAAAE50D;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: .375rem;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0.2rem;
    border-radius: .9325rem;
    background: #4A180526;
  }

  @media (max-width: 480px) {
    max-height: 26.5rem;
  }
`;

const Text = styled.p`
  margin: ${(props) => props.bold && '0'};
  width: 95%;
  font: ${(props) => (props.bold ? '600' : '200')} ${(props) => (props.bold ? '1rem' : '.85rem')} 'Soleto', sans-serif;
  color: ${(props) => (props.purple ? '#5321C4' : '#22254B')};
  font-style: ${(props) => props.italic && 'italic'};
  user-select: none;
  line-height: 1.375rem;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding-left: 6%;
`;

const ListItem = styled.li`
  margin: 0.25rem 0;
  width: 100%;
  font: 300 .625rem 'Soleto', sans-serif;
  color: #22254B;
  line-height: 1.375rem;
  user-select: none;
`;

const Link = styled.a`
  color: #505050;
`;

const PrivacyPolicyEN = ({ closeFunction }) => (
  <Overlay>
    <Wrapper>
      <CloseButton onClick={closeFunction}>
        <CloseIcon src={closeIcon} />
      </CloseButton>
      <Container>
        <Title bold>Privacy Policy</Title>
        <Content>
          <Text bold>Strytegy</Text>
          <Text purple>LAST UPDATED: July 6, 2021</Text>
          <Text>
            Thank you for choosing to use Strytegy, a tool developed by ISTI Soluções em Tecnologia Ltda, registered with the CNPJ number 13.475.139/0001-33, headquartered at Av. Pres. Wilson, 228, 13o andar, Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020 (<strong>“1STi”</strong>, <strong>“We”</strong>, <strong>“Us”</strong>, or <strong>“Our”</strong>). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at dpo@strytegy.com.
          </Text>
          <Text>
            When you visit our website https://www.strytegy.com, or any application related to it (<strong>“Site”</strong>), and use Our services, you trust us with your personal information. We take your privacy very seriously. In this Privacy Policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this Privacy Policy that you do not agree with, please discontinue use of our Site and our services.
          </Text>
          <Text>
            This Privacy Policy applies to all information collected through our Site and/or any related services, sales, marketing or events (we refer to them collectively in this Privacy Policy as <strong>“Services”</strong>).
          </Text>
          <Text>
            Please read this Privacy Policy carefully as it will help you make informed decisions about sharing your personal information with us.
          </Text>
          <Text bold>1. WHAT INFORMATION DO WE COLLECT?</Text>
          <Text>
            <strong>1.1 Personal information you disclose to us</strong>
          </Text>
          <Text italic>
            <strong>1.1.1 In short:</strong> We collect personal data that you voluntarily provide us when you register on our Site or when you contact us, such as name, email address, job title, photo, password and login data from third party services and/or social networks that you use to access the Site. We do not collect or have access to any information that you or any user enters into your projects within the Site.
          </Text>
          <Text>
            <strong>1.1.2 In more detail:</strong>
          </Text>
          <Text>
            <strong>a) Basic information:</strong> We collect personal data that you voluntarily provide to us by registering on the Site or by contacting us. The data collected at this time is your name, email address, job title, photo, password and login data from third party services, if you use them to register on our Site. From this information, the only ones necessary for the registration are e-mail address and password. It is up to you whether or not you wish to provide further information. As the Site is constantly evolving, we may eventually add new fields to fill in personal data, and it is up to you to fill them in or not.
          </Text>
          <Text>
            <strong>b) Login details from third party services:</strong> We provide you with the option to register using your Google account details. In addition, in the future we may provide you with the ability toregister using your accounts from other third-party services. If you choose to register on our Site this way, we will collect the information described in the section of this Privacy Policy entitled <Link href="#social-logins">“HOW DO WE HANDLE YOUR LOGIN VIA THIRD PARTY SERVICES?”</Link>.
          </Text>
          <Text>
            <strong>c) Responsibility for the accuracy and updating of personal data:</strong> All personal data you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal data.
          </Text>
          <Text>
            <strong>d) We do not have access to information entered into the projects:</strong> We do not collect nor do we have access to any information that you or any user enter into your projects within the Site. However, keep in mind that this information will be accessible to everyone with whom the projects are shared.
          </Text>
          <Text>
            <strong>1.2 Information collected automatically</strong>
          </Text>
          <Text italic>
            <strong>1.2.1 In short:</strong> Some information, such as IP address and/or browser and device characteristics, is automatically collected when you visit our Site and/or use our Services.
          </Text>
          <Text>
            <strong>1.2.2 In more detail:</strong> We automatically collect certain information when you visit, use or browse through our Site. This information does not reveal your specific identity (such as your name or contact information), but may include device usage information such as your IP address, browser and device characteristics, operating system, language preferences, referring urls, name of the device, country, location, information about how and when you use our Services, and other technical information. This information is primarily necessary to maintain the security and functioning of our Site and our Services and for internal analysis and reporting purposes to improve the quality of the Services. In addition, we collect information through cookies and similar technologies, for the same purpose.
          </Text>
          <Text bold>2. HOW DO WE USE YOUR INFORMATION?</Text>
          <Text italic>
            <strong>2.1.1 In short:</strong> We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations and with your consent.
          </Text>
          <Text>
            <strong>2.1.2 In more detail:</strong>
          </Text>
          <Text>
            <strong>a)</strong> We use the personal data mentioned in item 1 above, collected through our Site, for a variety of commercial purposes described below. We process your personal data for these purposes in reliance on Our legitimate business interests, in order to enter into or execute a contract with you, with your consent and/or to fulfill our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
          </Text>
          <Text>
            <strong>b)</strong> We use the information we collect or receive:
          </Text>
          <OrderedList type="1">
            <ListItem>
              <strong>To facilitate account creation and login process:</strong> If you choose to link your account with us to a third party account (such as your Google account), we use the information you have allowed us to collect from that third party to facilitate the account creation and login process for contract performance. See the section below titled <Link href="#social-logins">"HOW DO WE HANDLE YOUR LOGIN VIA THIRD PARTY SERVICES?"</Link> for more information;
            </ListItem>
            <ListItem>
              <strong>To send email marketing and promotional communications:</strong> We may use the personal data you provide us to send you e-mails informing you of new functionality on the Site and updates on our Services. If you no longer wish to receive this type of email, just let us know by clicking on the link that we include at the end of all emails enabling you to opt-out;
            </ListItem>
            <ListItem>
              <strong>To send administrative information to you:</strong> We may use your personal data to send you information about changes to Our terms, conditions and policies.
            </ListItem>
            <ListItem>
              <strong>To fulfill and manage your inquiries and provide support:</strong> We may use your personal data to fulfill and manage your inquiries and/or requests, including support, made in relation to the Site and our Services;
            </ListItem>
            <ListItem>
              <strong>To request evaluation:</strong> We may use your personal data to request evaluations (feedbacks) and to contact you about the use of our Site and our Services;
            </ListItem>
            <ListItem>
              <strong>To enforce our terms, conditions and policies;</strong>
            </ListItem>
            <ListItem>
              <strong>To respond to legal requests and prevent damages:</strong> If we receive a subpoena or other legal request, we may need to inspect the personal data mentioned in item 1 of this Privacy Policy to determine how to respond;
            </ListItem>
            <ListItem>
              <strong>Manage user accounts:</strong> We may use your information for the purpose of managing your account and keeping it working properly;
            </ListItem>
            <ListItem>
              <strong>For other business purposes:</strong> We may use your information for other business purposes such as analyzing data, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Site, Services, products, marketing and your experience. We may use and store this information on an aggregated and anonymized way so that it is not associated with individual end users and does not include personal data. We will not use personal data for purposes other than those provided for in this Privacy Policy without your consent, nor will we provide your personal data to third parties for any purpose other than providing the Services and accessing the Site.
            </ListItem>
          </OrderedList>
          <Text bold>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Text>
          <Text italic>
            <strong>3.1.1 In short:</strong> We only share information in the following situations: (a) with your consent; (b) to fulfill contractual obligations necessary to provide you with our Services; (c) to respond to your requests; (d) to comply with applicable law and comply with judicial or administrative orders; (e) to protect your rights and those of third parties. Under no circumstances we will share any information that has been entered by you in your projects within the Site.
          </Text>
          <Text>
            <strong>3.1.2 In more details:</strong>
          </Text>
          <Text>
            <strong>a)</strong> We may process or share data based on the following legal basis:
          </Text>
          <OrderedList type='1'>
            <ListItem>
              <strong>Consent:</strong> We may process your personal data if you have given us specific consent to use it for a specific purpose (e.g., registration on the Site);
            </ListItem>
            <ListItem>
              <strong>Compliance with contractual obligation:</strong> When you register on the Site, a business relationship is created between you and Us. We may process your personal data in order to fulfill the obligations assumed with you in the Terms of Use and Privacy Policy;
            </ListItem>
            <ListItem>
              <strong>Fulfill and manage your orders:</strong> We may use your personal data to fulfill and manage your requests made through our Site;
            </ListItem>
            <ListItem>
              <strong>Legal obligations:</strong> We may disclose your personal data when we are legally required to do so in order to comply with applicable law, government requests, a court process, court order or legal process, such as in response to a court order or a subpoena (including response to public authorities to meet national security or law enforcement requirements);
            </ListItem>
            <ListItem>
              <strong>Vital interests:</strong> We may use and/or disclose your personal data in the event that we believe it is necessary to investigate, prevent or take action on possible violations of our policies, suspected fraud, situations involving potential threats to anyone's security and illegal activities, or as an evidence in litigation in which we are involved.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>b)</strong> More specifically, we may need to process or share your information and personal data in the following situations:
          </Text>
          <OrderedList type='1'>
            <ListItem>
              <strong>Third Party Suppliers, Consultants and Other Service Providers:</strong> We may share your data with third party suppliers, service providers, contractors or agents who perform services for Us or on Our behalf and need access to this information to perform their work. Examples include: data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services and the Site, which will allow them to collect data about how you interact with the Services and the Site over time. This information can be used to, among other things, analyze and track data, determine the popularity of certain content, and better understand online activity. Our data processors are not authorized to use your personal data unless we have instructed them to do so. They will not share your personal data with any organization other than Us. They will keep your data secure and only for as long as is necessary and We determine;
            </ListItem>
            <ListItem>
              <strong>Business transfers:</strong> We may share or transfer your information in connection with, or during negotiations for any merger, sale of company assets, financing or acquisition of all, or a portion of, our business to another company, but for the specific purpose of continuing to provide the Services to you;
            </ListItem>
            <ListItem>
              <strong>Other Users:</strong> When you share personal data in projects on our Site, that personal data can be viewed by all users who have access to those projects.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>c)</strong> Under no circumstances we will share any information that has been entered by you or any other user in projects within the Site.
          </Text>
          <Text bold>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</Text>
          <Text italic>
            <strong>4.1.1 In short:</strong> We only share information with the following third parties.
          </Text>
          <Text>
            <strong>4.1.2 In more detail:</strong> We only share and disclose your information with the third parties listed below (We categorized each party so that you can easily understand the purpose of our data collection and processing practices). If we process your data based on your consent and you wish to revoke your consent, please contact us.
          </Text>
          <OrderedList type="a">
            <ListItem>
              <strong>Allow users to register via third party accounts (account registration and authentication):</strong> Google (if there is a possibility in the future of registering on our Site using other third party services, data will be shared with the third party that you choose to use to register on our Site);
            </ListItem>
            <ListItem>
              <strong>Website performance monitoring:</strong> Google Analytics and Amazon Pinpoint; and
            </ListItem>
            <ListItem>
              <strong>Cloud Information Storage:</strong> Amazon AWS.
            </ListItem>
          </OrderedList>
          <Text bold>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Text>
          <Text italic>
            <strong>5.1.1 In short:</strong> We may use cookies and other tracking technologies to collect and store information for the sole purpose of providing the Services.
          </Text>
          <Text>
            <strong>5.1.2 In more detail:</strong> Cookies are tools that record internet browsing behavior. We may use cookies and similar tracking technologies (such as web beacons and pixels) to access or store information, always with the aim of improving the quality of our Services.
          </Text>
          <Text bold id={'social-logins'}>
            6. HOW DO WE HANDLE YOUR LOGIN VIA THIRD PARTY SERVICES?
          </Text>
          <Text italic>
            <strong>6.1.1 In short:</strong> If you choose to register or log in to our Services using a Google account, or other third party account that is available, we may have access to certain information about you.
          </Text>
          <Text>
            <strong>6.1.2 In more detail:</strong>
          </Text>
          <Text>
            <strong>a)</strong> Our Services offer you the ability to register and log in using your Google account details. In addition, in the future we may provide you with the ability to register using your accounts for other third party services. If you choose to do so, we will receive certain personal data about you from your Google account or from another third party service you choose to register on the Site. The personal data we receive at this time only includes your name and email address.
          </Text>
          <Text>
            <strong>b)</strong> We will use the information we receive only for the purposes described in this Privacy Policy. Please note that we do not control and are not responsible for other uses of your personal data made by Google or the third party responsible for the service chosen by you to register on our Site. We recommend that you review the Terms of Use and Privacy Policy of the third party you choose to use the services to register on our Site, to understand how they collect, use and share your personal data and how you can set your privacy preferences on their websites and apps.
          </Text>
          <Text bold>7. HOW LONG DO WE KEEP YOUR INFORMATION?</Text>
          <Text italic>
            <strong>7.1.1 In short:</strong> We keep your personal data for the time necessary to fulfill the purposes described in this Privacy Policy, in compliance with the provisions of applicable law.
          </Text>
          <Text>
            <strong>7.1.2 In more detail:</strong>
          </Text>
          <Text>
            <strong>a)</strong> We will only keep your personal data for as long as it is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).
          </Text>
          <Text>
            <strong>b)</strong> When we no longer have any legitimate need to process your personal data, we will delete or anonymize it, or if this is not possible (for example, because your personal data has been stored in backup files), then we will store your personal data securely and we will insulate it from further processing until deletion is possible.
          </Text>
          <Text bold>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</Text>
          <Text italic>
            <strong>8.1.1 In short:</strong> Our objective is to protect your information and your personal data through a system of organizational and technical security measures.
          </Text>
          <Text>
            <strong>8.1.2 In more detail:</strong> We implement appropriate technical and organizational security measures designed to protect the security of any information we process (including personal data). However, please also remember that we cannot guarantee that the internet itself is 100% secure. You should only access the Site and Services within a secure environment and taking the necessary precautions to prevent third parties from accessing your data.
          </Text>
          <Text bold>9. DO WE COLLECT INFORMATION FROM MINORS?</Text>
          <Text italic>
            <strong>9.1.1 In short:</strong> The Brazilian law grants you the right to access and control your personal data. You can review, change or terminate your account at any time.
          </Text>
          <Text>
            <strong>9.1.2 In more detail:</strong> NWe do not request data from people who are considered minors under the Brazilian law. By using the Site and the Services, you represent that you are of legal age or that your registration on the Site and access to the Services is carried out with the permission and supervision of your legal guardians. If we learn that personal data from underage users has been collected, we will deactivate the account and take reasonable steps to promptly delete that data from Our records. If you become aware of any data we collect from underage persons, please contact us at dpo@strytegy.com.
          </Text>
          <Text bold id={'privacy-rights'}>
            10. WHAT ARE YOUR PRIVACY RIGHTS?
          </Text>
          <Text italic>
            <strong>10.1.1 In short:</strong> The Brazilian law grants you the right to access and control your personal data. You can review, change or terminate your account at any time.
          </Text>
          <Text>
            <strong>a)</strong> The Brazilian Federal law number 13.709/18 (Brazilian General Personal Data Protection Law – <strong>“LGPD”</strong>) grants you certain rights in regards to your personal data, including: (i) requesting access to and obtaining a copy of your personal data stored by Us; (ii) request rectification or deletion of your personal data (the information that is essential for your access to the Site and Services can only be deleted with the closure of your account); (iii) restrict the processing of your personal data (with the exception of the minimum information necessary to offer the Services); and (iv) if applicable, data portability. We will comply with any requests made by you and other users of the Site and Services in accordance with applicable data protection laws.
          </Text>
          <Text>
            <strong>b)</strong> If we are relying on your consent to process your personal data, you have the right to withdraw your consent at any time. However, please note that: (i) this will not affect the legality of the processing prior to its withdrawal; and (ii) the withdrawal of consent to the processing of personal data that are essential for the use of the Site and Services will result in the termination of your account.
          </Text>
          <Text>
            <strong>c)</strong> If you wish, at any time, to review or change the information in your account or terminate your account, you may: (i) make such review and change directly on the Site, for information which you can change by yourself; (ii) contact us via the email address provided at the end of this Privacy Policy to request such revision or change.
          </Text>
          <Text>
            <strong>d)</strong> Upon your request to close your account, We will deactivate or delete your account and information from Our active databases. However, some information may be retained in Our files to prevent fraud, solve problems, help with any investigations, enforce our Terms of Use and/or comply with legal requirements.
          </Text>
          <Text>
            <strong>e)</strong> Cookies and similar technologies: Most internet browsers are set to accept cookies by default. If you prefer, you can generally choose to set your browser to remove cookies and reject cookies. If you choose to remove cookies or reject cookies, this may affect certain features or services on our Site and our Services.
          </Text>
          <Text>
            <strong>f)</strong> Email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails we send (opt-out) or by contacting us using the details provided bellow. You will then be removed from the email marketing list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account.
          </Text>
          <Text bold>11. DATA BREACH</Text>
          <Text italic>
            <strong>11.1.1 In short:</strong> Whenever 1STi believes that there is a risk that part, or all of your personal data may have been breached, We will notify you, carry out the necessary investigation and inform the competent authorities as requested by the LGPD law.
          </Text>
          <Text>
            <strong>11.1.2 In more detail:</strong> A privacy breach occurs when there is unauthorized access or collection, use, disclosure or deletion of personal data. You will be notified of any data breaches when 1STi believes there is a risk that part or all of your personal data may have been breached. If 1STi becomes aware of a security breach that has resulted or may result in unauthorized access, use or disclosure of personal data, 1STi will investigate the matter and notify the appropriate authorities in the manner provided for in the LGPD law.
          </Text>
          <Text bold>12. DO WE MAKE UPDATES TO THIS PRIVACY POLICY?</Text>
          <Text italic>
            <strong>12.1.1 In short:</strong> Yes, we will update this Privacy Policy as necessary to remain in compliance with applicable law.
          </Text>
          <Text>
            <strong>12.1.2 In more detail:</strong> We may update this Privacy Policy from time to time. The updated version will always be available on the Site, with the date of the last update indicated at the beginning of the document. Updates will take effect as soon as they are accessible on the Site. If we make substantial changes to this Privacy Policy, we may notify you by posting notice of such changes or by directly sending you an email notification. We encourage you to review this Privacy Policy frequently to be informed of how we are protecting your information.
          </Text>
          <Text bold>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</Text>
          <Text>
            <strong>13.1.1</strong> If you have any questions or comments about this policy, you can contact Our data protection officer at the email address dpo@strytegy.com or by sending a letter to: 1STi Soluções (at the care of our data protection officer), Av. Pres. Wilson, 228, 13o andar, Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020.
          </Text>
          <Text>
            <strong>13.1.2 How can you review, update or delete the data we collect from you?</strong> Under the LGPD law, you have the right to request access to the personal data we collect from you, change that personal data or delete it under certain circumstances. Some data can be changed directly by you on the Site. In the case of personal data that you are unable to change directly on the Site, to request revision, update or deletion of your personal data, please send a request form to our data protection officer, at the email dpo@strytegy.com. We will respond as soon as possible.
          </Text>
        </Content>
      </Container>
    </Wrapper>
  </Overlay>
);

export default PrivacyPolicyEN;
