// Libs
import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { I18n } from '@aws-amplify/core';

// SVG
import Wavy from '../assets/images/wavy.inline.svg';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: .3; }
  100% { opacity: 1; }
`;

// Styles
const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;
`;

const WavyContainer = styled.div`
  position: relative;
  margin-top: ${props => props.isBlue ? '0' : '-2rem'};
  width: 100%;

  svg {
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    transform: rotateY(180deg);

    ${({ isBlue }) => isBlue && `
      path {
        fill: #22254B;
      }
    `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  padding: 10rem 0 4rem;
  max-width: 1440px;

  @media (max-width: 768px) {
    width: calc(100% - 80px);
  }

  @media (max-width: 480px) {
    padding: 4rem 0 3rem;
  }
`;

const Title = styled.h2`
  margin-bottom: 1.125rem;
  font: 200 3.4275rem 'Paralucent', sans-serif;
  color: ${props => props.color};
  text-align: center;
  font-weight: 600;

  ${({ isVisible }) => isVisible && css`
    animation: ${OpacityAnimation} 2s;
    opacity: 1;
  `}

  @media (max-width: 480px) {
    width: 100%;
    text-align: left;
    font-size: 2.125rem;
    line-height: 2.25rem;
  }
`;

const Description = styled.span`
  display: inline-block;
  width: 75%;
  margin: 1.5rem 0 .5rem;
  font: 300 1.375rem 'Soleto', sans-serif;
  color: ${props => props.color};
  text-align: center;
  line-height: 1.875rem;
  letter-spacing: .33px;

  ${({ isVisible }) => isVisible && css`
    animation: ${OpacityAnimation} 3.5s;
    opacity: 1;
  `}

  @media (max-width: 480px) {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5625rem;
  }
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.375rem;
  height: 3.625rem;
  margin: 3.1875rem auto 0;
  border: none;
  border-radius: .3125rem;
  background: #FFCE06;
  font: 500 1.25rem 'Soleto', sans-serif;
  color: #22254B;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .3s;

  ${({ isVisible }) => isVisible && css`
    animation: ${OpacityAnimation} 5s;
    opacity: 1;
  `}

  @media (min-width: 769px) {
    :hover {
      width: 13.875rem;
      height: 4.125rem;
      margin-top: 2.9375rem;
      margin-bottom: -.25rem;
    }
  }
`;

const dict = {
  en: {
    'contact-title-highlight': 'Collaborate remotely',
    'contact-description': 'We want to be the preferred partner in your next big step. Invite your team, partners and stakeholders to create projects and generate results in an uncomplicated, intuitive and friendly way.',
    'contact-description-2': 'All of this with free access.',
    'contact-button': 'Start now, free!',
  },
  'pt-BR': {
    'contact-title-highlight': 'Colabore remotamente',
    'contact-description': 'Nós queremos ser o parceiro preferencial em seu próximo grande passo. Convide seu time, parceiros e stakeholders para criar projetos e gerar resultados de uma forma descomplicada, intuitiva e amigável.',
    'contact-description-2': 'E tudo isso com acesso gratuito.',
    'contact-button': 'Crie agora!',
  },
};

const Contact = ({
  language,
  type,
  isBlue
}) => {
  const [isTitleVisible, setTitleVisible] = useState(false);
  const [isSubTitleVisible, setSubTitleVisible] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(false);

  const contactTitleRef = useRef();
  const contactSubTitleRef = useRef();
  const contactButtonRef = useRef();

  useEffect(() => {
    const observerTitle = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRect.bottom >= entry.rootBounds.height * .8) {
          setTitleVisible(entry.isIntersecting)
        }
        if (!entry.isIntersecting) {
          setTitleVisible(entry.isIntersecting);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.2, 0.5, 1.0]
    });

    const observerSubTitle = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRect.bottom >= entry.rootBounds.height * .8) {
          setSubTitleVisible(entry.isIntersecting);
        }
        if (!entry.isIntersecting) {
          setSubTitleVisible(entry.isIntersecting);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.2, 0.5, 1.0]
    });

    const observerList = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRect.bottom >= entry.rootBounds.height * .8) {
          setButtonVisible(entry.isIntersecting);
        }
        if (!entry.isIntersecting) {
          setButtonVisible(entry.isIntersecting);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.2, 0.5, 1.0]
    });

    observerTitle.observe(contactTitleRef.current);
    observerSubTitle.observe(contactSubTitleRef.current);
    observerList.observe(contactButtonRef.current);

    return () => {
      observerTitle.unobserve(contactTitleRef.current); // eslint-disable-line react-hooks/exhaustive-deps
      observerSubTitle.unobserve(contactSubTitleRef.current); // eslint-disable-line react-hooks/exhaustive-deps
      observerList.unobserve(contactButtonRef.current); // eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []);

  I18n.setLanguage(language === 'pt-BR' ? 'pt-BR' : 'en');
  I18n.putVocabularies(dict);

  return (
    <Container>
      <WavyContainer isBlue={isBlue}>
        <Wavy />
      </WavyContainer>
      <Wrapper>
        <Title
          ref={contactTitleRef}
          isVisible={isTitleVisible}
          color={type === 'home'? '#22254B' : '#FFF'}
        >
          {I18n.get('contact-title-highlight')}
        </Title>
        <Description
          ref={contactSubTitleRef}
          isVisible={isSubTitleVisible}
          color={type === 'home'? '#22254B': '#FFF'}
        >
          {I18n.get('contact-description')}
          <br />
          {I18n.get('contact-description-2')}
        </Description>
        <Button
          ref={contactButtonRef}
          isVisible={isButtonVisible}
          href={`https://go.strytegy.com/signup?language=${language === 'pt-BR' ? 'pt-br' : 'en-us'}`}
          target='_blank'
          rel='noopener'
        >
          {I18n.get('contact-button')}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default Contact;