// Libs
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Contact from './Contact';
import { I18n } from '@aws-amplify/core';
import { Link } from 'gatsby';

// Assets
import logoIcon from '../assets/images/strytegy-logo-mini.svg';

// Styles
const Container = styled.section`
  width: 100%;

  ${({ isHome }) => isHome ? `
    background-image: linear-gradient(#5321C41A 10%, #22254B1A 100%);
  ` : `
    background-color: #22254B;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 238px);
  max-width: 1440px;
  margin: 0 auto;
  padding: 3rem 0 10rem;
  border-top: 1px solid ${props => props.isHome ? '#22254B0D' : '#ffffff33'};

  @media (max-width: 768px) {
    align-items: center;
    width: calc(100% - 80px);
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const About = styled.div`
  width: 40%;
  min-width: 320px;

  @media (max-width: 480px) {
    min-width: auto;
    width: 100%;
  }
`;

const Title = styled.h3`
  font: 600 1.25rem 'Paralucent', sans-serif;
  color: ${props => props.color};
`;

const Description = styled.span`
  display: inline-block;
  margin: 0;
  font: 400 .875rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.5625rem;
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  @media (max-width: 480px) {
    margin-top: 2.875rem;
  }
`;

const Logo = styled.img`
  width: 3.3125rem;
`;

const LogoText = styled.p`
  margin: 0;
  margin-left: 1rem;
  font: 300 1.0625rem 'Paralucent', sans-serif;
  color: ${props => props.color};

  @media (max-width: 480px) {
    font-size: 1.0625rem;
  }
`;

const CompanyBox = styled.div`
  margin-top: 1rem;
`;

const CompanyText = styled.p`
  margin: 0;
  font: 300 .75rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.125rem;
`;

const Lists = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 35%;

  @media (max-width: 480px) {
    justify-content: space-between;
    width: 100%;
    margin-top: 3.5rem;
  }
`;

const Product = styled.div`
  width: 50%;

  @media (max-width: 480px) {
    width: 30%;
  }
`;

const Help = styled.div`
  width: 50%;

  @media (max-width: 480px) {
    width: 60%;
  }
`;

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListLinks = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  font: 400 .875rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.5625rem;
`;

const LinkMedia = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const ItemLink = styled(Link)`
  display: block;
  font: 400 .875rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.5625rem;
  text-decoration: none;
  cursor: pointer;
`;

const TermsButton = styled.button`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font: 400 .875rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.5625rem;
  text-decoration: none;
  text-align: left;
  cursor: pointer;
  outline: 0;
`;

const EmailLink = styled.a`
  display: block;
  font: 400 .875rem 'Soleto', sans-serif;
  color: ${props => props.color};
  line-height: 1.5625rem;
  text-decoration: none;
  cursor: pointer;
`;

const dict = {
  en: {
    'footer-title': 'About us',
    'footer-description': 'Strytegy was formed some years ago with the aim of answering this question: What separates successful initiatives from the many that fail?',
    'stories-label': 'Stories',
    'help-label': 'Help',
    'support-button': 'Support',
    'service-terms': 'Terms of Use',
    'privacy-policy': 'Privacy Policy'
  },
  'pt-BR': {
    'footer-title': 'Sobre nós',
    'footer-description': 'O Strytegy foi formado há alguns anos com o objetivo de responder a esta pergunta: O que separa as iniciativas bem-sucedidas das muitas que fracassam?',
    'stories-label': 'Histórias',
    'help-label': 'Ajuda',
    'support-button': 'Suporte',
    'service-terms': 'Termos de Uso',
    'privacy-policy': 'Políticas de Privacidade'
  },
};

const Footer = ({
  type,
  language,
  setTerms,
  setPrivacy,
  isBlue
}) => {
  const [isVisible, setVisible] = useState(false);

  const footerRef = useRef();

  I18n.setLanguage(language === 'pt-BR' ? 'pt-BR' : 'en');
  I18n.putVocabularies(dict);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.2, 0.5, 1.0]
    });

    observer.observe(footerRef.current);

    return () => observer.unobserve(footerRef.current); // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      ref={footerRef}
      isVisible={isVisible}
      isHome={type === 'home'}
    >
      <Contact
        type={type}
        language={language}
        isBlue={isBlue}
      />
      <Wrapper isHome={type === 'home'}>
        <About>
          <Title
            color={type === 'home' ? '#22254B' : '#FFF'}
          >
            {I18n.get('footer-title')}
          </Title>
          <Description
            color={type === 'home' ? '#22254B' : '#FFF'}
          >
            {I18n.get('footer-description')}
          </Description>
          <LogoBox>
            <Logo src={logoIcon} alt='strytegy logo' />
            <LogoText
              color={type === 'home' ? '#22254B' : '#FFF'}
            >
              © 2021 Strytegy
            </LogoText>
          </LogoBox>
          <CompanyBox>
            <CompanyText
              color={type === 'home' ? '#22254B' : '#FFF'}
            >
              ISTI Soluções em Tecnologia Ltda
              <br />
              CNPJ: 13.475.139/0001-33
            </CompanyText>
          </CompanyBox>
        </About>
        <Lists>
          <Product>
            <Title
              color={type === 'home' ? '#22254B' : '#FFF'}
            >
              Social
            </Title>
            <List>
              <Item
                color={type === 'home' ? '#22254B' : '#FFF'}
              >
                <LinkMedia
                  href='https://www.instagram.com/go.strytegy/'
                  target='_blank'
                  rel='noopener'
                >
                  Instagram
                </LinkMedia>
              </Item>
              <Item
                color={type === 'home' ? '#22254B' : '#FFF'}
              >
                <LinkMedia
                  href='https://www.facebook.com/go.strytegy/'
                  target='_blank'
                  rel='noopener'
                >
                  Facebook
                </LinkMedia>
              </Item>
              <Item
                color={type === 'home' ? '#22254B' : '#FFF'}
              >
                <LinkMedia
                  href='https://www.linkedin.com/company/strytegy/about/'
                  target='_blank'
                  rel='noopener'
                >
                  Linkedin
                </LinkMedia>
              </Item>
              <ItemLink
                color={type === 'home' ? '#22254B' : '#FFF'}
                to={language === 'pt-BR' ? '/blog/pt-br/' : '/blog/en-us/'}
              >
                Blog
              </ItemLink>
            </List>
          </Product>
          <Help>
            <Title
              color={type === 'home' ? '#22254B' : '#FFF'}
            >
              {I18n.get('help-label')}
            </Title>
            <ListLinks>
              <ItemLink
                color={type === 'home' ? '#22254B' : '#FFF'}
                to={language === 'pt-BR' ? '/faq/pt-br/' : '/faq/en-us/'}
              >
                FAQ
              </ItemLink>
              <TermsButton
                color={type === 'home' ? '#22254B' : '#FFF'}
                onClick={() => setTerms(true)}
              >
                {I18n.get('service-terms')}
              </TermsButton>
              <TermsButton
                color={type === 'home' ? '#22254B' : '#FFF'}
                onClick={() => setPrivacy(true)}
              >
                {I18n.get('privacy-policy')}
              </TermsButton>
              <EmailLink
                href='mailto:hey@strytegy.com'
                color={type === 'home' ? '#22254B' : '#FFF'}
              >
                {I18n.get('support-button')}
              </EmailLink>
            </ListLinks>
          </Help>
        </Lists>
      </Wrapper>
    </Container>
  );
};

export default Footer;