// Libs 
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, } from 'gatsby';
import { I18n } from '@aws-amplify/core';

// Assets
import logo from '../assets/images/logo.png';
import arrowIcon from '../assets/images/dropdown-arrow.svg';

// Animations
const ShowAnimation = (a, b) => keyframes`
  0% { transform: translateY(${a}); }
  100% { transform: translateY(${b}); }
`;

// Animations
const SizeAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

// Styles
const Container = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: #FFFFFFF7;
  box-shadow: 0 3px 10px #0000001A;
  backdrop-filter: blur(5px);
  z-index: 2;

  @media (max-width: 480px) {
    height: 4rem;
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 238px);
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: calc(100% - 80px);
    padding-right: 2.5rem;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
    margin: 0;
    margin-left: auto;
    padding-right: 0;
    background-color: #FFFFFF;
  }
`;

const Logo = styled.img`
  width: 8.875rem;
  margin: 0;

  @media (max-width: 480px) {
    width: auto;
    height: 1.875rem;
  }
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 1.5rem;
    background: #fff;
    box-shadow: 0 4px 6px #00000029;
    animation: ${props => props.close ? ShowAnimation('0', '-100%') : ShowAnimation('-100%', '0')} .8s;
  }
`;

const Item = styled.span`
  position: relative;
  display: block;
  margin: 0 1.25rem;
  padding: 0 .5rem;
  border-bottom: 1.5px solid transparent;
  font: 400 1.3125rem 'Paralucent', sans-serif;
  color: #25294C;
  cursor: pointer;

  ${({ isActive }) => isActive && css`
    ::after {
      content: '';
      position: absolute;
      bottom: -.25rem;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #22254B;
      animation: ${SizeAnimation} .1s linear;
      z-index: 0;
    }
  `}

  @media (min-width: 769px) {
    :hover {
      ::after {
        content: '';
        position: absolute;
        bottom: -.25rem;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #22254B;
        animation: ${SizeAnimation} .1s linear;
        z-index: 0;
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
    margin: 2rem 0;
    order: 2;
  }
`;

const Button = styled.button`
  position: relative;
  width: 11.25rem;
  height: 3rem;
  margin-left: 1.5rem;
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: #FFEB97;
  overflow: hidden;
  outline: none;

  @media (min-width: 769px) {
    :hover {
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FFCE06;
        animation: ${SizeAnimation} .25s linear;
        z-index: 0;
      }
    }
  }

  @media (max-width: 480px) {
    margin-left: 0;
    order: 1;
  }
`;

const ButtonLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  font: 500 1.125rem 'Soleto', sans-serif;
  color: #22254B;
  text-align: center;
  line-height: 3rem;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
`;

const LanguageButton = styled.button`
  position: absolute;
  right: -4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  padding: 0;
  margin: 0;
  border: 1px solid #22254B;
  border-radius: 50%;
  background-color: transparent;
  font: 400 .85rem 'Paralucent', sans-serif;
  color: #25294C;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  @media (max-width: 768px) {
    right: -1rem;
  }

  @media (max-width: 480px) {
    position: static;
    order: 3;
  }
`;

const ContainerMenu = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  height: calc(100vh - 4rem);
  z-index: -1;
`;

const ArrowButton = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  width: 1rem;
  transition: all .2s;

  ${({ isOpen }) => isOpen && `
    transform: rotate(180deg);
  `}
`;

const Line = styled.hr`
  display: none;

  @media (max-width: 480px) {
    position: absolute;
    bottom: 0;
    display: initial;
    width: 90%;
    height: .5px;
    margin: 0;
    background-color: #22254B;
    z-index: 1;
  }
`;

const dict = {
  en: {
    'login-button': 'Enter',
    'stories-button': 'Stories',
  },
  'pt-BR': {
    'login-button': 'Entrar',
    'stories-button': 'Histórias',
  },
};

const Header = ({
  language,
  isMobile,
  type,
}) => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isCloseMenu, setCloseMenu] = useState(false);

  I18n.setLanguage(language === 'pt-BR' ? 'pt-BR' : 'en');
  I18n.putVocabularies(dict);

  const getInternacionalizationRouter = () => {
    if (type === 'home') {
      return language === 'pt-BR' ? '/en-us/' : '/pt-br/';
    }
    if (type === 'faq') {
      return language === 'pt-BR' ? '/faq/en-us/' : '/faq/pt-br/'
    }
    if (type === 'blog') {
      return language === 'pt-BR' ? '/blog/en-us/' : '/blog/pt-br/'
    }
  }

  const handleShowMenu = () => {
    if (isOpenMenu) {
      setCloseMenu(true);

      setTimeout(() => {
        setOpenMenu(false)
      }, 750);
    } else {
      setCloseMenu(false);
      setOpenMenu(true);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(handleShowMenu);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderButtons = () => {
    const isActiveFaq = typeof window !== 'undefined'
      ? window?.location?.pathname === '/faq/pt-br/' || window?.location?.pathname === '/faq/en-us/'
      : false;

    const isActiveBlog = typeof window !== 'undefined'
      ? window?.location?.pathname === '/blog/pt-br/' || window?.location?.pathname === '/blog/en-us/'
      : false;

    return (
      <Navigation
        close={isCloseMenu}
      >
        <Item
          isActive={isActiveBlog}
        >
          <Link
            to={language === 'pt-BR' ? '/blog/pt-br/' : '/blog/en-us/'}
            onClick={handleShowMenu}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Blog
          </Link>
        </Item>
        <Item
          isActive={isActiveFaq}
        >
          <Link
            to={language === 'pt-BR' ? '/faq/pt-br/' : '/faq/en-us/'}
            onClick={handleShowMenu}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            FAQ
          </Link>
        </Item>
        {/* <Item>
              {I18n.get('stories-button')}
            </Item> */}
        <Button>
          <ButtonLink
            href='https://go.strytegy.com'
            target='_blank'
            rel='noopener'
            onClick={handleShowMenu}
          >
            {I18n.get('login-button')}
          </ButtonLink>
        </Button>
        <LanguageButton>
          <Link
            to={getInternacionalizationRouter()}
            onClick={handleShowMenu}
            style={{ width: '100%', height: '100%', color: 'inherit', textDecoration: 'none', lineHeight: '1.75rem' }}
          >
            {language === 'pt-BR' ? 'PT' : 'EN'}
          </Link>
        </LanguageButton>
      </Navigation>
    );
  }

  return (
    <Container>
      <Wrapper id='header-box'>
        <Link
          to={language === 'pt-BR' ? '/pt-br/' : '/en-us/'}
          style={{ display: 'inline-flex' }}
        >
          <Logo
            src={logo}
            alt='strytegy logo'
          />
        </Link>
        {!isMobile && renderButtons()}
        {isMobile &&
          <ArrowButton onClick={handleShowMenu}>
            <ArrowIcon
              src={arrowIcon}
              isOpen={isOpenMenu}
              alt='arrow icon'
            />
          </ArrowButton>}
        {isOpenMenu && <Line />}
      </Wrapper>
      {isMobile && isOpenMenu &&
        <ContainerMenu onClick={handleShowMenu}>
          {renderButtons()}
        </ContainerMenu>}
    </Container>
  );
}

export default Header;
