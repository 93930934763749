/* eslint-disable */
// Libs
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Images
import closeIcon from '../assets/images/close.svg';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// style properties
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #0c153480;
  animation: ${OpacityAnimation} 0.2s;
  z-index: 2;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30.625rem;
  border-radius: 0.625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 .875rem #00000014;
  overflow: hidden;

  @media (max-width: 480px) {
    margin: 0;
    width: 95%;
    justify-content: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled.img`
  width: 1rem;
`;

const Container = styled.div`
  padding: 2.8125rem;
  width: 100%;

  @media (max-width: 480px) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  margin: 0 0 2.625rem;
  font: 600 2.1875rem 'Paralucent', sans-serif;
  color: #22254B;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
  max-height: 30.5rem;
  border-radius: .3125rem;
  border: .0625rem solid #22254B0D;
  background: #CAAAE50D;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: .375rem;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0.2rem;
    border-radius: .9325rem;
    background: #4A180526;
  }

  @media (max-width: 480px) {
    max-height: 26.5rem;
  }
`;

const Text = styled.p`
  margin: ${(props) => props.bold && '0'};
  width: 95%;
  font: ${(props) => (props.bold ? '600' : '200')} ${(props) => (props.bold ? '1rem' : '.85rem')} 'Soleto', sans-serif;
  color: ${(props) => (props.purple ? '#5321C4' : '#22254B')};
  font-style: ${(props) => props.italic && 'italic'};
  user-select: none;
  line-height: 1.375rem;
`;

const OrderedList = styled.ol`
  width: 100%;
  padding-left: 6%;
`;

const ListItem = styled.li`
  margin: 0.25rem 0;
  width: 100%;
  font: 300 .625rem 'Soleto', sans-serif;
  color: #22254B;
  user-select: none;
`;

const Link = styled.a`
  color: #505050;
`;


const PrivacyPolicyPT = ({ closeFunction }) => (
  <Overlay>
    <Wrapper>
      <CloseButton onClick={closeFunction}>
        <CloseIcon src={closeIcon} />
      </CloseButton>
      <Container>
        <Title bold>Políticas de Privacidade</Title>
        <Content>
          <Text bold>Strytegy</Text>
          <Text purple>ÚLTIMA ATUALIZAÇÃO: 06 de julho de 2021</Text>
          <Text>
            Obrigado por escolher utilizar o Strytegy, uma ferramenta desenvolvida pela ISTI Soluções em Tecnologia Ltda, inscrita no CNPJ sob o n. 13.475.139/0001-33, com sede na Av. Pres. Wilson, 228, 13º andar, Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020 (<strong>“1STi”</strong> ou <strong>“Nós”</strong> ou <strong>“Nossos”</strong>). Nós estamos comprometidos em proteger suas informações, seus dados pessoais e seu direito à privacidade. Se você tiver alguma dúvida ou preocupação sobre nossa política ou nossas práticas em relação aos seus dados pessoais, entre em contato conosco no endereço de e-mail dpo@strytegy.com.
          </Text>
          <Text>
            Quando você visita nosso site https://www.strytegy.com ou qualquer aplicativo a ele relacionado (<strong>“Site”</strong>) e usa Nossos serviços, você fornece a Nós certos dados pessoais. Levamos sua privacidade muito a sério. Nesta Política de Privacidade, buscamos explicar a você de maneira mais clara possível quais informações coletamos, como as usamos e quais direitos você tem em relação a elas. Esperamos que você tire algum tempo para ler com cuidado, pois é importante. Se houver algum termo nesta Política de Privacidade com o qual você não concorda, por favor, interrompa o uso de nosso Site e Nossos serviços.
          </Text>
          <Text>
            Esta Política de Privacidade se aplica a todas as informações coletadas através de nosso Site e/ou quaisquer serviços relacionados, vendas, marketing ou eventos (nos referimos coletivamente a eles nesta Política de Privacidade como os <strong>"Serviços"</strong>).
          </Text>
          <Text>
            Leia atentamente esta Política de Privacidade, pois ela irá ajudá-lo a tomar decisões informadas sobre o compartilhamento de suas informações conosco.
          </Text>
          <Text bold>1. QUAIS INFORMAÇÕES COLETAMOS?</Text>
          <Text>
            <strong>1.1 Dados pessoais que você nos divulga</strong>
          </Text>
          <Text italic>
            <strong>1.1.1 Resumindo:</strong> Coletamos dados pessoais que você voluntariamente nos fornece ao realizar o seu cadastro em nosso Site ou ao entrar em contato conosco, como nome, endereço de e-mail, cargo, foto, senha e dados de login de serviços de terceiros e/ou redes sociais que você utilize para acessar o Site. Não coletamos nem temos acesso a qualquer informação que você ou qualquer usuário inserir em seus projetos dentro do Site.
          </Text>
          <Text>
            <strong>1.1.2 De forma mais detalhada:</strong>
          </Text>
          <Text>
            <strong>a) Informações básicas:</strong> Coletamos dados pessoais que você voluntariamente nos fornece ao se registrar no Site ou ao entrar em contato conosco. Os dados coletados neste momento são nome, endereço de e-mail, cargo, foto, senha e dados de login de serviços de terceiros, caso você os utilize para se registrar em nosso Site. Destas informações, as únicas necessárias para a realização do cadastro são endereço de e-mail e senha. Fica a seu critério se deseja ou não fornecer as demais informações. Como o Site está em constante evolução, eventualmente poderemos adicionar novos campos para preenchimento de dados pessoais, ficando a seu critério preenchê-las ou não.
          </Text>
          <Text>
            <strong>b) Dados de login de serviços de terceiros:</strong> Nós fornecemos a você a opção de se registrar usando detalhes de sua conta Google. Além disso, futuramente poderemos fornecer a possibilidade de se registrar utilizando suas contas de outros serviços de terceiros. Caso você opte por se registrar no Site desta forma, coletaremos as informações descritas na seção desta Política de Privacidade denominada <Link href="#social-logins">“COMO LIDAMOS COM SEU LOGIN VIA SERVIÇOS DE TERCEIROS”</Link>.
          </Text>
          <Text>
            <strong>c) Responsabilidade pela veracidade e atualização dos dados pessoais:</strong> Todos os dados pessoais que você nos fornece devem ser verdadeiros, completos e precisos, e você deve nos notificar de quaisquer alterações em tais dados pessoais.
          </Text>
          <Text>
            <strong>d) Não temos acesso às informações inseridas nos projetos:</strong> Não coletamos nem temos acesso a qualquer informação que você ou qualquer usuário inserir em seus projetos dentro do Site. Porém, lembre-se que essas informações poderão ser acessadas por todas as pessoas com as quais os projetos forem compartilhados.
          </Text>
          <Text>
            <strong>1.2 Informações coletadas automaticamente</strong>
          </Text>
          <Text italic>
            <strong>1.2.1 Resumindo:</strong> Algumas informações, como endereço IP e/ou características do navegador e do dispositivo, são coletadas automaticamente quando você visita nosso Site e/ou usa nossos Serviços.
          </Text>
          <Text>
            <strong>1.2.2 De forma mais detalhada:</strong> Coletamos automaticamente certas informações quando você visita, usa ou navega pelo nosso Site. Essas informações não revelam sua identidade específica (como seu nome ou informações de contato), mas podem incluir informações de dispositivo de uso, como seu endereço IP, características do navegador e do dispositivo, sistema operacional, preferências de idioma, urls referentes, nome do dispositivo, país, localização, informações sobre como e quando você usa nossos Serviços e outras informações técnicas. Essas informações são necessárias principalmente para manter a segurança e o funcionamento de nosso Site e de nossos Serviços e para fins de análise interna e relatórios com a finalidade de melhorar a qualidade dos Serviços. Além disso, coletamos informações através de cookies e tecnologias similares, com a mesma finalidade.
          </Text>
          <Text bold>2. COMO USAMOS SUAS INFORMAÇÕES?</Text>
          <Text italic>
            <strong>2.1.1 Resumindo:</strong> Processamos suas informações para fins baseados em interesses comerciais legítimos, o cumprimento de nosso contrato com você, o cumprimento de nossas obrigações legais e com o seu consentimento.
          </Text>
          <Text>
            <strong>2.1.2 De forma mais detalhada:</strong>
          </Text>
          <Text>
            <strong>a)</strong> Usamos os dados pessoais mencionados no item 1 acima, coletados através de nosso Site, para uma variedade de propósitos comerciais descritos abaixo. Processamos seus dados pessoais para esses fins com base em Nossos legítimos interesses comerciais, a fim de firmar ou executar um contrato com você, com seu consentimento e/ou para o cumprimento de nossas obrigações legais. Indicamos a seguir os motivos específicos de processamento ao lado de cada finalidade.
          </Text>
          <Text>
            <strong>b)</strong> Usamos as informações que coletamos ou recebemos:
          </Text>
          <OrderedList type="1">
            <ListItem>
              <strong>Para facilitar a criação de contas e o processo de login:</strong> Se você optar por vincular sua conta conosco a uma conta de terceiros (como sua conta do Google), usamos as informações que você nos permitiu coletar desse terceiro para facilitar a criação de contas e o processo de login para o desempenho do contrato. Veja a seção abaixo com o título <Link href="#social-logins">"COMO LIDAMOS COM SEU LOGIN VIA SERVIÇOS DE TERCEIROS"</Link> para obter mais informações;
            </ListItem>
            <ListItem>
              <strong>Para enviar e-mail marketing e comunicações promocionais:</strong> Nós podemos usar os dados pessoais que você nos fornece para enviar a você e-mails informando sobre novas funcionalidades do Site e novidades em nossos Serviços. Caso você não deseje receber mais esse tipo de e-mail, basta nos informar clicando no link que incluímos ao final de todos os emails possibilitando o opt-out;
            </ListItem>
            <ListItem>
              <strong>Para enviar informações administrativas para você:</strong> Podemos usar seus dados pessoais para enviar informações sobre alterações em Nossos termos, condições e políticas;
            </ListItem>
            <ListItem>
              <strong>Cumprir e gerenciar suas solicitações e consultas e oferecer suporte:</strong> Podemos usar seus dados pessoais para cumprir e gerenciar suas consultas e/ou solicitações, inclusive de suporte, feitas em relação ao Site e aos Serviços;
            </ListItem>
            <ListItem>
              <strong>Solicitação de avaliação:</strong> Podemos usar seus dados pessoais para solicitar avaliações (feedbacks) e entrar em contato com você sobre o uso de nosso Site e nossos Serviços;
            </ListItem>
            <ListItem>
              <strong>Para fazer cumprir Nossos termos, condições e políticas;</strong>
            </ListItem>
            <ListItem>
              <strong>Responder a pedidos legais e prevenir danos:</strong> Se recebermos uma intimação ou outra solicitação legal, talvez precisemos inspecionar os dados pessoais mencionados no item 1 desta Política de Privacidade para determinar como responder;
            </ListItem>
            <ListItem>
              <strong>Gerenciar contas de usuários:</strong> Podemos usar suas informações para fins de gerenciar sua conta e mantê-la em funcionamento;
            </ListItem>
            <ListItem>
              <strong>Para outros fins comerciais:</strong> Podemos usar suas informações para outros fins comerciais, como análise de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas promocionais e para avaliar e melhorar nosso Site, nossos Serviços, produtos, marketing e a sua experiência. Podemos usar e armazenar essas informações de forma agregada e anonimizada para que não esteja associada a usuários finais individuais e não inclua dados pessoais. Não usaremos dados pessoais para finalidades diversas das previstas nesta Política de Privacidade sem o seu consentimento, nem forneceremos seus dados pessoais para terceiros para qualquer finalidade diversa da prestação dos Serviços e acesso ao Site.
            </ListItem>
          </OrderedList>
          <Text bold>3. SUAS INFORMAÇÕES SERÃO COMPARTILHADAS COM ALGUÉM?</Text>
          <Text italic>
            <strong>3.1.1 Resumindo:</strong> Nós apenas compartilhamos informações nas seguintes situações: (a) com o seu consentimento; (b) para cumprir obrigações contratuais necessárias para fornecerlhe os Serviços; (c) para responder às suas solicitações; (d) para cumprir a legislação aplicável e atender ordens judiciais ou administrativas; (e) para proteger seus direitos e de terceiros. Em nenhuma hipótese compartilharemos qualquer informação que tiver sido inserida por você em seus projetos dentro do site.
          </Text>
          <Text>
            <strong>3.1.2 De forma mais detalhada:</strong>
          </Text>
          <Text>
            <strong>a)</strong> Podemos processar ou compartilhar dados com base nas seguintes bases legais:
          </Text>
          <OrderedList type='1'>
            <ListItem>
              <strong>Consentimento:</strong> Podemos processar seus dados pessoais se você nos deu consentimento específico para usá-los em um propósito específico (por exemplo, cadastro no Site);
            </ListItem>
            <ListItem>
              <strong>Cumprimento de obrigação contratual:</strong> Quando você se cadastra no Site, nasce uma relação comercial entre você e Nós. Podemos processar seus dados pessoais para cumprir as obrigações assumidas com você nos Termos de Uso e Política de Privacidade;
            </ListItem>
            <ListItem>
              <strong>Cumprir e gerenciar suas ordens:</strong> Podemos usar seus dados pessoais para cumprir e gerenciar suas solicitações feitas através do nosso Site;
            </ListItem>
            <ListItem>
              <strong>Obrigações legais:</strong> Podemos divulgar seus dados pessoais quando formos legalmente obrigados a fazê-lo a fim de cumprir a lei aplicável, solicitações governamentais, um processo judicial, ordem judicial ou processo legal, como em resposta a uma ordem judicial ou uma intimação (inclusive em resposta às autoridades públicas para atender aos requisitos de segurança nacional ou de aplicação da lei);
            </ListItem>
            <ListItem>
              <strong>Interesses vitais:</strong> Podemos utilizar e/ou divulgar seus dados pessoais na hipótese de entendermos ser necessário investigar, prevenir ou tomar medidas sobre possíveis violações de nossas políticas, suspeita de fraude, situações envolvendo potenciais ameaças à segurança de qualquer pessoa e atividades ilegais, ou como evidência em litígios nos quais estejamos envolvidos.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>b)</strong> Mais especificamente, podemos precisar processar ou compartilhar suas informações e seus dados pessoais nas seguintes situações:
          </Text>
          <OrderedList type='1'>
            <ListItem>
              <strong>Fornecedores, consultores e outros provedores de serviços de terceiros:</strong> Podemos compartilhar seus dados com fornecedores, prestadores de serviços, empreiteiros ou agentes que realizam serviços para Nós ou em Nosso nome e necessitam de acesso a essas informações para realizar seu trabalho. Exemplos incluem: análise de dados, entrega de e-mails, serviços de hospedagem, atendimento ao cliente e esforços de marketing. Podemos permitir que terceiros selecionados usem a tecnologia de rastreamento nos Serviços e no Site, o que lhes permitirá coletar dados sobre como você interage com os Serviços e o Site ao longo do tempo. Essas informações podem ser usadas para, entre outras coisas, analisar e rastrear dados, determinar a popularidade de determinado conteúdo e entender melhor a atividade online. Nossos processadores de dados não têm autorização para utilizar os seus dados pessoais, a menos que tenhamos os instruído a fazê-lo. Eles não compartilharão os seus dados pessoais com nenhuma organização além de Nós. Eles manterão os seus dados seguros e apenas pelo período que for necessário e Nós determinarmos;
            </ListItem>
            <ListItem>
              <strong>Transferências de negócios:</strong> Podemos compartilhar ou transferir suas informações em conexão com, ou durante negociações de qualquer fusão, venda de ativos da empresa, financiamento ou aquisição de todos ou uma parte do nosso negócio para outra empresa, porém com a finalidade específica de continuar prestando os Serviços a você;
            </ListItem>
            <ListItem>
              <strong>Outros usuários:</strong> Quando você compartilha dados pessoais em projetos no Site, esses dados pessoais podem ser visualizados por todos os usuários que tenham acesso a esses projetos.
            </ListItem>
          </OrderedList>
          <Text>
            <strong>c)</strong> Em nenhuma hipótese compartilharemos qualquer informação que tiver sido inserida por você ou qualquer outro usuário em projetos dentro do Site.
          </Text>
          <Text bold>4. COM QUEM SUAS INFORMAÇÕES SERÃO COMPARTILHADAS?</Text>
          <Text italic>
            <strong>4.1.1 Resumindo:</strong> Somente compartilhamos informações com os terceiros indicados a seguir.
          </Text>
          <Text>
            <strong>4.1.2 De forma mais detalhada:</strong> Nós apenas compartilhamos e divulgamos suas informações com os terceiros indicados a seguir (Nós categorizamos cada parte para que você possa entender facilmente o propósito de nossas práticas de coleta e processamento de dados). Se processamos seus dados com base no seu consentimento e deseja revogar seu consentimento, entre em contato conosco.
          </Text>
          <OrderedList type="a">
            <ListItem>
              <strong>Permitir que os usuários se registrem via contas de terceiros (registro e autenticação da conta):</strong> Google (caso no futuro haja a possibilidade de se registrar em nosso Site usando outros serviços de terceiros, haverá o compartilhamento de dados com o terceiro que você optar por utilizar para se registrar em nosso Site);
            </ListItem>
            <ListItem>
              <strong>Monitoramento de desempenho do site:</strong> Google Analytics e Amazon Pinpoint; e
            </ListItem>
            <ListItem>
              <strong>Armazenamento das informações em nuvem:</strong> Amazon AWS.
            </ListItem>
          </OrderedList>
          <Text bold>5. USAMOS COOKIES E OUTRAS TECNOLOGIAS DE RASTREAMENTO?</Text>
          <Text italic>
            <strong>5.1.1 Resumindo:</strong> Podemos usar cookies e outras tecnologias de rastreamento para coletar e armazenar informações, com a finalidade exclusiva de prestar os Serviços.
          </Text>
          <Text>
            <strong>5.1.2 De forma mais detalhada:</strong> Cookies são ferramentas que registram o comportamentos de navegação na internet. Podemos usar cookies e tecnologias de rastreamento similares (como web beacons e pixels) para acessar ou armazenar informações, sempre no intuito de melhorar a qualidade dos Serviços.
          </Text>
          <Text bold id={'social-logins'}>
            6. COMO LIDAMOS COM SEU LOGIN VIA SERVIÇOS DE TERCEIROS?
          </Text>
          <Text italic>
            <strong>6.1.1 Resumindo:</strong> Se você optar por se registrar ou fazer login em nossos Serviços usando uma conta Google, ou outra conta de terceiros que esteja disponível, podemos ter acesso a certas informações sobre você.
          </Text>
          <Text>
            <strong>6.1.2 De forma mais detalhada:</strong>
          </Text>
          <Text>
            <strong>a)</strong> Nossos Serviços oferecem a você a capacidade de se registrar e fazer login usando os detalhes de sua conta Google. Além disso, futuramente poderemos fornecer a possibilidade de se registrar utilizando suas contas de outros serviços de terceiros. Caso você opte por fazer isso, receberemos certos dados pessoais sobre você a partir de sua conta Google ou de outro serviço de terceiros escolhido por você para realizar o seu registro no Site. Os dados pessoais que recebemos neste momento incluem apenas nome e endereço de e-mail.
          </Text>
          <Text>
            <strong>b)</strong> Usaremos as informações que recebemos apenas para os fins descritos nesta Política de Privacidade. Por favor, note que não controlamos e não somos responsáveis por outros usos de seus dados pessoais feito pelo Google ou pelo terceiro responsável pelo serviço escolhido por você para se registrar em nosso Site. Recomendamos que você revise os termos de uso e a política de privacidade do terceiro que você escolher utilizar os serviços para realizar seu registro em nosso Site, para entender como eles coletam, usam e compartilham seus dados pessoais e como você pode definir suas preferências de privacidade em seus sites e aplicativos.
          </Text>
          <Text bold>7. QUANTO TEMPO GUARDAMOS SEUS DADOS PESSOAIS?</Text>
          <Text italic>
            <strong>7.1.1 Resumindo:</strong> Mantemos seus dados pessoais pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, respeitando o previsto na legislação aplicável.
          </Text>
          <Text>
            <strong>7.1.2 De forma mais detalhada:</strong>
          </Text>
          <Text>
            <strong>a)</strong> Só manteremos seus dados pessoais pelo tempo necessário para os fins estabelecidos nesta Política de Privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei (como impostos, contabilidade ou outros requisitos legais).
          </Text>
          <Text>
            <strong>b)</strong> Quando não tivermos mais nenhuma necessidade legítima para processar seus dados pessoais, vamos excluí-los ou anonimizá-los, ou, se isso não for possível (por exemplo, porque seus dados pessoais foram armazenados em arquivos de backup), então armazenaremos seus dados pessoais com segurança e os isolaremos de qualquer processamento adicional até que a exclusão seja possível.
          </Text>
          <Text bold>8. COMO MANTEMOS SUAS INFORMAÇÕES SEGURAS?</Text>
          <Text italic>
            <strong>8.1.1 Resumindo:</strong> Nosso objetivo é proteger suas informações e seus dados pessoais através de um sistema de medidas de segurança organizacional e técnica.
          </Text>
          <Text>
            <strong>8.1.2 De forma mais detalhada:</strong> Implementamos medidas de segurança técnica e organizacional adequadas projetadas para proteger a segurança de quaisquer informações que processarmos (incluindo dados pessoais). No entanto, lembre-se também que não podemos garantir que a própria internet seja 100% segura. Você só deve acessar o Site e os Serviços dentro de um ambiente seguro e tomando os cuidados necessários para evitar que terceiros acessem seus dados.
          </Text>
          <Text bold>9. COLETAMOS DADOS PESSOAIS DE MENORES DE IDADE?</Text>
          <Text italic>
            <strong>9.1.1 Resumindo:</strong> Não coletamos dados de pessoas que sejam considerados menores de idade pela legislação brasileira.
          </Text>
          <Text>
            <strong>9.1.2 De forma mais detalhada:</strong> Não solicitamos dados de pessoas que sejam consideradas menores de idade pela legislação brasileira. Ao usar o Site e os Serviços, você garante que você é maior de idade ou que o seu registro no Site e acesso aos Serviços é realizado com a permissão e acompanhamento de seus responsáveis legais. Se soubermos que dados pessoais de usuários menores de idade foram coletados, desativaremos a conta e tomaremos medidas razoáveis para excluir prontamente esses dados de Nossos registros. Se você tomar conhecimento de quaisquer dados que coletamos de pessoas menores de idade, entre em contato conosco em dpo@strytegy.com.
          </Text>
          <Text bold id={'privacy-rights'}>
            10. QUAIS SÃO SEUS DIREITOS DE PRIVACIDADE?
          </Text>
          <Text italic>
            <strong>10.1.1 Resumindo:</strong> A legislação brasileira garante a você direitos de acesso e controle sobre seus dados pessoais. Você pode revisar, alterar ou encerrar sua conta a qualquer momento.
          </Text>
          <Text>
            <strong>a)</strong> A Lei n. 13.709/18 (Lei Geral de Proteção de Dados Pessoais – <strong>“LGPD”</strong>) garante a você certos direitos em relação aos seus dados pessoais, dentre os quais estão incluídos: (i) solicitar acesso e obter uma cópia de seus dados pessoais armazenados por Nós; (ii) solicitar retificação ou eliminação dos seus dados pessoais (as informações que são essenciais para o seu acesso ao Site e aos Serviços só poderão ser eliminadas com o encerramento da sua conta); (iii) restringir o processamento de seus dados pessoais (com exceção das informações mínimas necessárias para o oferecimento dos Serviços); e (iv) se aplicável, a portabilidade de dados. Atenderemos qualquer solicitação feita por você e os demais usuários do Site e dos Serviços de acordo com as leis aplicáveis de proteção de dados.
          </Text>
          <Text>
            <strong>b)</strong> Se estamos confiando no seu consentimento para processar seus dados pessoais, você tem o direito de retirar seu consentimento a qualquer momento. No entanto, note que: (i) isso não afetará a legalidade do processamento antes de sua retirada; e (ii) a retirada do consentimento para processamento de dados pessoais que sejam essenciais para o uso do Site e dos Serviços implicará no encerramento de sua conta.
          </Text>
          <Text>
            <strong>c)</strong> Se você quiser, a qualquer momento, revisar ou alterar as informações em sua conta ou encerrar sua conta, você pode: (i) fazer essa revisão e alteração diretamente no Site, para as informações às quais você tem a possibilidade de alterar por si mesmo; (ii) entrar em contato conosco por meio do endereço de e-mail indicado na parte final desta Política de Privacidade para solicitar referida revisão ou alteração.
          </Text>
          <Text>
            <strong>d)</strong> Após sua solicitação para encerrar sua conta, desativaremos ou excluiremos sua conta e informações de Nossos bancos de dados ativos. No entanto, algumas informações podem ser retidas em Nossos arquivos para evitar fraudes, solucionar problemas, ajudar com quaisquer investigações, aplicar nossos Termos de Uso e/ou cumprir os requisitos legais.
          </Text>
          <Text>
            <strong>e)</strong> Cookies e tecnologias similares: A maioria dos navegadores de internet está definida para aceitar cookies por padrão. Se preferir, você geralmente pode optar por configurar seu navegador para remover cookies e rejeitar cookies. Se você optar por remover cookies ou rejeitar cookies, isso pode afetar certos recursos ou serviços de nosso Site e de nossos Serviços.
          </Text>
          <Text>
            <strong>f)</strong> E-mail marketing: Você pode cancelar a assinatura da nossa lista de e-mails marketing a qualquer momento clicando no link de cancelamento de inscrição nos e-mails que enviamos (opt-out) ou entrando em contato conosco usando os detalhes fornecidos abaixo. Em seguida, você será removido da lista de e-mails de marketing – no entanto, ainda precisaremos enviar e-mails relacionados ao serviço que são necessários para a administração e uso de sua conta.
          </Text>
          <Text bold>11. VIOLAÇÃO DE DADOS</Text>
          <Text italic>
            <strong>11.1.1 Resumindo:</strong> Sempre que a 1STi acreditar que há risco de ter ocorrido vazamento de parte ou da totalidade seus dados pessoais, Nós notificaremos você, realizaremos a investigação necessária e informações as autoridades competentes na forma prevista na LGPD.
          </Text>
          <Text>
            <strong>11.1.2 De forma mais detalhada:</strong> Uma violação de privacidade ocorre quando há acesso ou coleta, uso, divulgação ou eliminação de dados pessoais não autorizados. Você será notificado sobre eventuais violações de dados quando a 1STi acreditar que há risco de ter ocorrido vazamento de parte ou da totalidade seus dados pessoais. Caso a 1STi tome conhecimento de uma falha de segurança que resultou ou pode resultar em acesso, uso ou divulgação não autorizado de dados pessoais, a 1STi investigará o assunto e notificará as autoridades competentes na forma prevista na LGPD.
          </Text>
          <Text bold>12. FAZEMOS ATUALIZAÇÕES NESTA POLÍTICA DE PRIVACIDADE?</Text>
          <Text italic>
            <strong>12.1.1 Resumindo:</strong> Sim, atualizaremos esta Política de Privacidade conforme necessário para manter-nos em conformidade com a legislação aplicável.
          </Text>
          <Text>
            <strong>12.1.2 De forma mais detalhada:</strong> Podemos atualizar esta Política de Privacidade de tempos em tempos. A versão atualizada sempre estará disponível no Site, indicando no início do documento a data da última atualização. As atualizações entrarão em vigor assim que estiverem acessíveis no Site. Se fizermos alterações substanciais nesta Política de Privacidade, podemos notificá-lo postando um aviso de tais alterações ou enviando diretamente uma notificação por e-mail. Nós encorajamos você a rever esta Política de Privacidade com frequência para se manter informado de como estamos protegendo suas informações.
          </Text>
          <Text bold>13. COMO VOCÊ PODE ENTRAR EM CONTATO CONOSCO SOBRE A POLÍTICA DE PRIVACIDADE?</Text>
          <Text>
            <strong>13.1.1</strong> Se você tiver dúvidas ou comentários sobre esta política, você pode entrar em contato com Nosso encarregado pelo tratamento de dados pessoais através do endereço de e-mail dpo@strytegy.com ou enviando correspondência para: 1STi Soluções (aos cuidados do encarregado pelo tratamento de dados pessoais) Av. Pres. Wilson, 228, 13º andar, Centro, Rio de Janeiro/RJ, Brasil, CEP 20030-020
          </Text>
          <Text>
            <strong>13.1.2 Como você pode revisar, atualizar ou excluir os dados que coletamos de você?</strong> De acordo com a LGPD, você tem o direito de solicitar acesso aos dados pessoais que coletamos de você, alterar esses dados pessoais ou excluí-los em algumas circunstâncias. Alguns dados podem ser alterados diretamente por você no Site. No caso de dados pessoais que você não consegue alterar diretamente no Site, para solicitar a revisão, atualização ou excluir seus dados pessoais envie uma solicitação para o nosso encarregado pelo tratamento de dados pessoais, no endereço de e-mail dpo@strytegy.com. Responderemos com a maior brevidade possível.
          </Text>
        </Content>
      </Container>
    </Wrapper>
  </Overlay>
);

export default PrivacyPolicyPT;
